import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Grid, Button, TextField, CircularProgress } from "@mui/material";
import { Autocomplete, Box, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  CERTIFICATE_ID,
  COMPANY_DETAILS_FORM,
  IMAGE_UPLOAD_ERROR,
  ICON_ID,
  ReferenceData,
  SNACKBAR_ERROR_MSG,
  SUCCESS_KEY,
  BROWSE_FILE_MSG,
  ERROR_KEY,
  FILE_SIZE_MSG,
  NUMBER_ONLY_REGEX,
  LETTERS_ONLY_REGEX,
  ALPHA_NUMERIC_REGEX,
  FORM_SUBMISSION_SUCCESS,
  ALPHA_NUMERIC_SPECIALCHARS_REGEX,
} from "../constants";
import { postFormData, getFormData } from "../services/FormDataService";
import { useAppSelector } from "../services/StoreHooks";
import { UpdateUser, UploadFiles, UserFiles } from "../services/UserService";
import Notification from "../components/Notification";
import { useAppDispatch } from "../services/StoreHooks";
import { ThemeProvider } from "@mui/material/styles";
import {
  useStyles,
  StyledContainer,
  formLabelsTheme,
} from "../InternalStyles/CompanyFormOneStyles";
import {
  FormAttributes,
  PINCODE_REGEX,
  LETTERS_REGEX,
  URL_REGEX,
  COUNTRY_FILTER,
} from "../ConstantValues/CompanyFormOneConstants";
import {
  MIN_MSG,
  CITY_REQ,
  STATE_REQ,
  ADDR1_REQ,
  ADDR2_REQ,
  COUNTRY_REQ,
  WEBSITE_REQ,
  PINCODE_REQ,
  FIRSTNAME_MSG,
  LASTNAME_MSG,
  EMAIL_ERR_MSG,
  EMAIL_REQ_MSG,
  PINCODE_ERR_MSG,
  PHN_ERR_MSG,
  PHN_REQ_MSG,
  COMPANY_REQ,
  DESIGNATION_REQ,
  LANDLINE_ERR_MSG,
  COMPANY_DESC_MSG,
  SPECIAL_CHAR_ERR_MSG,
  LINKEDIN_REQ,
} from "../Messages/CompanyFormOneMessages";
import {
  FORM_MAX_WIDTH,
  AUTO_MARGIN,
  FORM_GRID_SPACING,
  FULL_SIZE_GRID,
  HALF_SIZE_GRID,
  PRIMARY_THEME_COLOR,
  DASHED_BORDER,
} from "../InternalStyles/CommonStyleVariables";

const CompanyFormOne: FC<any> = (props) => {
  const userDataState = useAppSelector((state) => state.currentUser);
  const { setActiveStep } = props;
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const companyType = ["Sole Proprietorship", "Partnership", "Limited Liability Partnership",
  "Private Limited Companies", "Public Limited Companies", "One-Person Companies"];
  const [showNotifcation, setShowNofitication] = useState(false);
  const [notifcationMessage, setNoticationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [dataId, setdataId] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [gotData, setGotData] = React.useState(false);
  const [certificateID, setCertificateID] = React.useState("");
  const [iconID, setIconID] = React.useState("");
  const [countries, setCountries] = React.useState<any[]>([]);
  const [iconImage, setIconImage] = React.useState(false);
  const [certificateFile, setCertificateFile] = React.useState(false);
  const [companyLogoFiles, setCompanyLogoFiles] = React.useState<any[]>([]);
  const [companyCertificates, setCompanyCertificates] = React.useState<any[]>(
    []
  );

  const dispatch = useAppDispatch();

  const formOne = useFormik({
    initialValues: {
      companyName: "",
      companyDescription: "",
      uploadCompanyLogo: "",
      uploadCertificate: "",
      registrationYear: "",
      firstName: userDataState.userData.firstName || "",
      lastName: userDataState.userData.lastName || "",
      designation: "",
      linkedIn: "",
      companyWebsite: "",
      officeEmail: userDataState.userData.emailId || "",
      countryCode: "",
      phoneNumber: userDataState.userData.mobileNumber || "",
      landlineNumber: "",
      companyType: "",
      alternativeEmail: "",
      addressLine1: "",
      addressLine2: "",
      pincode: "",
      city: "",
      state: "",
      country: "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string()
        .required(COMPANY_REQ)
        .min(3, MIN_MSG)
        .matches(ALPHA_NUMERIC_SPECIALCHARS_REGEX,''),
      companyDescription: Yup.string()
        .required(COMPANY_DESC_MSG)
        .matches(LETTERS_REGEX, SPECIAL_CHAR_ERR_MSG),
      uploadCertificate: Yup.string(),
      registrationYear: Yup.string().matches(
        NUMBER_ONLY_REGEX,
        SPECIAL_CHAR_ERR_MSG
      ),
      firstName: Yup.string()
        .max(25)
        .min(3)
        .required(FIRSTNAME_MSG)
        .matches(LETTERS_ONLY_REGEX, SPECIAL_CHAR_ERR_MSG),
      lastName: Yup.string()
        .max(25)
        .min(1)
        .required(LASTNAME_MSG)
        .matches(LETTERS_ONLY_REGEX, SPECIAL_CHAR_ERR_MSG),
      designation: Yup.string()
        .required(DESIGNATION_REQ)
        .matches(LETTERS_ONLY_REGEX, SPECIAL_CHAR_ERR_MSG),
      companyWebsite: Yup.string().required(WEBSITE_REQ),
      companyType: Yup.string(),
      linkedIn: Yup.string().required(LINKEDIN_REQ).matches(URL_REGEX),
      officeEmail: Yup.string().email(EMAIL_ERR_MSG).required(EMAIL_REQ_MSG),
      alternativeEmail: Yup.string().email(EMAIL_ERR_MSG),
      phoneNumber: Yup.string()
        .matches(phoneRegExp, PHN_ERR_MSG)
        .length(12)
        .required(PHN_REQ_MSG),
      landlineNumber: Yup.string().matches(phoneRegExp, LANDLINE_ERR_MSG).max(20),
      addressLine1: Yup.string().required(ADDR1_REQ),
      addressLine2: Yup.string().required(ADDR2_REQ),
      pincode: Yup.string()
        .required(PINCODE_REQ)
        .length(6)
        .matches(PINCODE_REGEX, PINCODE_ERR_MSG),
      city: Yup.string()
        .required(CITY_REQ)
        .matches(LETTERS_ONLY_REGEX, SPECIAL_CHAR_ERR_MSG),
      state: Yup.string()
        .required(STATE_REQ)
        .matches(LETTERS_ONLY_REGEX, SPECIAL_CHAR_ERR_MSG),
      country: Yup.string().required(COUNTRY_REQ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    },
    enableReinitialize: true,
  });

  const handleSubmit = async (values: any, setSubmitting: any) => {
    setSubmitting(false);

    postFormValues(values);

    // formOne.setValues({
    //   ...apiData
    // })
  };

  const uploadingData = async () => {
    const fileIds: { iconId: string; certificateId: string } = {
      iconId: "",
      certificateId: "",
    };
    if (acceptedFilesCompanyLogo.length > 0 && !iconID) {
      const uploadResponse = await UploadFiles(uploadPayloadBuild()).catch(
        (error) => {
          props.setType(ERROR_KEY);
          props.setDataMessage(IMAGE_UPLOAD_ERROR);
          props.setOpen(true);
          console.log(error);
        }
      );
      if (uploadResponse?.data?.success) {
        setIconID(uploadResponse?.data?.data?.id);
        fileIds.iconId = uploadResponse?.data?.data?.id;
      }
    }
    if (acceptedFilesCertificate.length > 0 && !certificateID) {
      const uploadResponse = await UploadFiles({
        documentTypeId: CERTIFICATE_ID,
        documentPath: `certificates/${userDataState.userId}`,
        documentName: userDataState.userId,
        files: acceptedFilesCertificate,
      }).catch((error) => {
        props.setType(ERROR_KEY);
        props.setDataMessage(IMAGE_UPLOAD_ERROR);
        props.setOpen(true);
        console.log(error);
      });
      if (uploadResponse?.data?.success) {
        setCertificateID(uploadResponse?.data?.data?.id);
        fileIds.certificateId = uploadResponse?.data?.data?.id;
      }
    }
    return fileIds;
  };

  const formPayload = (
    values: any,
    files: { iconId: string; certificateId: string }
  ) => ({
    formId: COMPANY_DETAILS_FORM,
    formData: {
      userId: userDataState.userId,
      companyName: values.companyName,
      companyDescription: values.companyDescription,
      uploadCertificate: values.uploadCertificate,
      spoc: {
        firstName: values.firstName,
        lastName: values.lastName,
        designation: values.designation,
      },
      companyWebsite: values.companyWebsite,
      officeEmail: values.officeEmail,
      linkedIn: values.linkedIn,
      alternativeEmail: values.alternativeEmail,
      countryCode: values.countryCode,
      phoneNumber: values.phoneNumber,
      landlineNumber: values.landlineNumber,
      companyType: values.companyType,
      registrationYear: values.registrationYear,
      certificateID: files?.certificateId || certificateID,
      iconID: files?.iconId || iconID,
      companyAddress: {
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        pincode: values.pincode,
        city: values.city,
        state: values.state,
        country: values.country,
      },
    },
  });

  const postFormValues = async (values: any) => {
    setLoader(true);
    const fileIDObj = await uploadingData();
    const postData = formPayload(values, fileIDObj);
    const response = await postFormData(
      dataId ? Object.assign({ ...postData, id: dataId }) : postData
    );

    if (response?.data?.success) {
      dispatch({
        type: "USER_ADD",
        data: {
          userData: {
            ...userDataState.userData,
            companyId: response?.data?.data?.id,
          },
          userId: userDataState.userId,
        },
      });
      if (!userDataState.userData.companyId)
        await updateUser(response?.data?.data?.id);
      setLoader(false);
      setShowNofitication(true);
      setNotificationType(SUCCESS_KEY);
      props.handleComplete();
      props.setFormOneFilled(true);
      setNoticationMessage(FORM_SUBMISSION_SUCCESS);
      setTimeout(() => {
        setNoticationMessage("");
        setNotificationType(SUCCESS_KEY);
        setShowNofitication(false);
        setActiveStep((prevState: any) => prevState + 1);
      }, 3500);
    } else {
      setNoticationMessage(SNACKBAR_ERROR_MSG);
      setNotificationType(ERROR_KEY);
      setShowNofitication(true);
      setLoader(false);
      setTimeout(() => {
        setNoticationMessage("");
        setNotificationType(SUCCESS_KEY);
        setShowNofitication(false);
      }, 3000);
    }
  };

  useEffect(() => {
    const tempCountries = props.referenceData
      .filter((data: ReferenceData) => data.type === COUNTRY_FILTER)
      .map((country: ReferenceData) => country.displayText);
    setCountries((country) => [...tempCountries]);
    const fillFormCompany = async () => {
      const formOneData = await getFormData(
        COMPANY_DETAILS_FORM,
        userDataState.userId
      );
      if (formOneData?.data?.data.length > 0) {
        patchFormData({
          ...formOneData?.data?.data[0].formData,
          id: formOneData?.data?.data[0].id
        });
      }
    };
    const getFormValues = async () => {
      const userFilesData = await UserFiles(iconID);
      const blob = new Blob([userFilesData.data]);
      setCompanyLogoFiles(() => [
        new File([blob], "name", { type: "application/png" }),
      ]);
      setIconImage(true);
    };
    const getCertificates = async () => {
      const certificatesData = await UserFiles(certificateID);
      const blob = new Blob([certificatesData.data]);
      setCompanyCertificates(() => [
        new File([blob], "name", { type: "application/pdf" }),
      ]);
      setCertificateFile(true);
    };
    const formData = props.formOneDetails;
    if (formData.id) {
      setIconID(formData.iconID);
      setCertificateID(formData.certificateID);
      patchFormData(formData);
    }
    fillFormCompany();
    if (iconID) getFormValues();
    if (certificateID) getCertificates();
    setGotData(true);
  }, [gotData]);

  function generateArrayOfYears() {
    var max = new Date().getFullYear();
    var min = max - 72;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }

  const patchFormData = (formData: any) => {
    setdataId(formData.id);
    setIconID(formData.iconID);
    setCertificateID(formData.certificateID);
    const data = formData;
    const data1 = data.companyAddress;
    const data2 = data.spoc;
    formOne.setValues({
      ...data,
      ...data1,
      ...data2,
    });
  };

  const uploadPayloadBuild = () => {
    return {
      documentTypeId: ICON_ID,
      documentPath: `icons/${userDataState.userId}`,
      documentName: userDataState.userId,
      files: acceptedFilesCompanyLogo,
    };
  };

  var years = generateArrayOfYears();
  const yearData = years.map(String);

  // const handleChange = (e: any) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.id]: e.target.value,
  //   });
  //   console.log(formData);
  // };

  const classes = useStyles();

  const {
    acceptedFiles: acceptedFilesCompanyLogo,
    getRootProps: rootPropsCompanyLogo,
    getInputProps: inputPropsCompanyLogo,
    open: openCompanyLogo,
  } = useDropzone({
    disabled: false,
    onDrop: () => {
      setIconImage(false);
      setIconID("");
    },
  });
  const {
    acceptedFiles: acceptedFilesCertificate,
    getRootProps: rootPropsCertificate,
    getInputProps: inputPropsCertificate,
    open: openCertificate,
  } = useDropzone({
    disabled: false,
    onDrop: () => {
      setCertificateFile(false);
      setCertificateID("");
    },
  });

  const updateUser = async (companyId: string) => {
    const userPayload = {
      userData: {
        ...userDataState.userData,
        companyId,
      },
      userId: userDataState.userId,
    };

    const userResponse = await UpdateUser(userPayload);
  };

  return (
    <ThemeProvider theme={formLabelsTheme}>
      <StyledContainer maxWidth={"sm"}>
        <Notification
          message={notifcationMessage}
          type={notificationType}
          open={showNotifcation}
        />
        {gotData ? (
          <form onSubmit={formOne.handleSubmit}>
            <Grid
              container
              spacing={FORM_GRID_SPACING}
              className="form-top-grid"
            >
              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
              >
                <TextField
                  label={FormAttributes.companyName.label}
                  name="companyName"
                  required
                  id={FormAttributes.companyName.id}
                  className={classes.inputField}
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.companyName}
                  error={
                    formOne.touched.companyName &&
                    Boolean(formOne.errors.companyName)
                  }
                  helperText={
                    formOne.touched.companyName && formOne.errors.companyName
                  }
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <TextField
                  multiline
                  rows={HALF_SIZE_GRID}
                  label={FormAttributes.companyDescription.label}
                  id={FormAttributes.companyDescription.id}
                  className={classes.inputFieldCustom}
                  onBlur={formOne.handleBlur}
                  required
                  onChange={formOne.handleChange}
                  value={formOne.values.companyDescription}
                  error={
                    formOne.touched.companyDescription &&
                    Boolean(formOne.errors.companyDescription)
                  }
                  helperText={
                    formOne.touched.companyDescription &&
                    formOne.errors.companyDescription
                  }
                  fullWidth

                  // value={lastName}
                  // onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>

              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <Typography
                  variant="h5"
                  marginBottom={HALF_SIZE_GRID}
                  className={classes.Heading}
                >
                  Upload Company Logo
                </Typography>
                <Paper
                  sx={{
                    borderRadius: 4,
                  }}
                  variant="outlined"
                >
                  <Box
                    sx={{ border: DASHED_BORDER, p: 3, m: 3 }}
                    {...rootPropsCompanyLogo({ className: "dropzone" })}
                  >
                    <input {...inputPropsCompanyLogo()} />
                    <Typography variant="body1" color="blue" textAlign="left">
                      Drag & drop{" "}
                      <Typography fontSize={20} color="blue" textAlign="right">
                        +
                      </Typography>
                    </Typography>
                    <Typography>Your file here or browse</Typography>
                    <aside>
                      {iconImage
                        ? companyLogoFiles.map((file: any) => (
                            <li key={file.path || file.name}>
                              {userDataState.userId} - {file.size} bytes
                            </li>
                          ))
                        : acceptedFilesCompanyLogo.map((file: any) => (
                            <li key={file.path || file.name}>
                              {file.path} - {file.size} bytes
                            </li>
                          ))}
                    </aside>
                  </Box>
                </Paper>
                <Box
                  sx={{
                    display: {
                      xs: "block",
                      sm: "flex",
                      md: "flex",
                      lg: "flex",
                    },
                    justifyContent: "space-between",
                    p: 2,
                  }}
                  className={classes.limitWidth}
                >
                  <Box>
                    <Box
                      textAlign="left"
                      onClick={openCompanyLogo}
                      className={classes.browseFiles}
                    >
                      {BROWSE_FILE_MSG}
                    </Box>
                    <Box>{FILE_SIZE_MSG}</Box>
                  </Box>

                  <Box
                    textAlign="right"
                    onClick={openCompanyLogo}
                    className={classes.uploadLogoText}
                  >
                    Upload Logo
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <TextField
                  multiline
                  rows={4}
                  label={FormAttributes.uploadCertificate.label}
                  id={FormAttributes.uploadCertificate.id}
                  className={classes.inputFieldCustom}
                  fullWidth
                  name="uploadCertificate"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.uploadCertificate}
                  error={
                    formOne.touched.uploadCertificate &&
                    Boolean(formOne.errors.uploadCertificate)
                  }
                  helperText={
                    formOne.touched.uploadCertificate &&
                    formOne.errors.uploadCertificate
                  }
                />
                <Box
                  sx={{
                    display: {
                      xs: "block",
                      sm: "flex",
                      md: "flex",
                      lg: "flex",
                    },
                    justifyContent: "space-between",
                    p: 2,
                  }}
                  className={classes.limitWidth}
                >
                  <Box>
                    <Box
                      textAlign="left"
                      onClick={openCertificate}
                      className={classes.browseFiles}
                    >
                      {BROWSE_FILE_MSG}
                    </Box>
                    <Box>{FILE_SIZE_MSG}</Box>
                    <aside>
                      {certificateFile
                        ? companyCertificates.map((file: any) => (
                            <li key={file.path || file.name}>
                              {userDataState.userId} - {file.size} bytes
                            </li>
                          ))
                        : acceptedFilesCertificate.map((file: any) => (
                            <li key={file.path || file.name}>
                              {file.path} - {file.size} bytes
                            </li>
                          ))}
                    </aside>
                  </Box>

                  <Box
                    textAlign="right"
                    onClick={openCertificate}
                    className={classes.uploadLogoText}
                  >
                    Upload Certificate
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <Autocomplete
                  disablePortal
                  className={classes.inputField}
                  options={yearData}
                  id="companyYearOfRegistration"
                  onBlur={formOne.handleBlur}
                  onChange={(e, value, reason) =>
                    formOne.setFieldValue("registrationYear", value)
                  }
                  value={formOne.values.registrationYear}
                  //   getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      label={FormAttributes.registrationYear.label}
                      id={FormAttributes.registrationYear.id}
                      name="registrationYear"
                      error={
                        formOne.touched.registrationYear &&
                        Boolean(formOne.errors.registrationYear)
                      }
                      helperText={
                        formOne.touched.registrationYear &&
                        formOne.errors.registrationYear
                      }
                    />
                  )}
                  // value={formState.checklistId}
                  // onChange={(
                  //     event: any,
                  //     newValue: string | null,
                  //     reason: string
                  // ) => {
                  //     handleChange(event, newValue, 'checklistId', reason);
                  // }}
                />
              </Grid>

              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={HALF_SIZE_GRID}
                lg={HALF_SIZE_GRID}
                className={classes.limitLeft}
              >
                <TextField
                  required
                  label={FormAttributes.firstName.label}
                  id={FormAttributes.firstName.id}
                  className={classes.inputField}
                  fullWidth
                  name="firstName"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.firstName}
                  error={
                    formOne.touched.firstName &&
                    Boolean(formOne.errors.firstName)
                  }
                  helperText={
                    formOne.touched.firstName && formOne.errors.firstName
                  }
                />
              </Grid>

              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={HALF_SIZE_GRID}
                lg={HALF_SIZE_GRID}
                className={classes.limitRight}
              >
                <TextField
                  required
                  label={FormAttributes.lastName.label}
                  id={FormAttributes.lastName.id}
                  className={classes.inputField}
                  fullWidth
                  name="lastName"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.lastName}
                  error={
                    formOne.touched.lastName && Boolean(formOne.errors.lastName)
                  }
                  helperText={
                    formOne.touched.lastName && formOne.errors.lastName
                  }
                />
              </Grid>

              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <TextField
                  required
                  label={FormAttributes.designation.label}
                  id={FormAttributes.designation.id}
                  className={classes.inputField}
                  fullWidth
                  name="designation"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.designation}
                  error={
                    formOne.touched.designation &&
                    Boolean(formOne.errors.designation)
                  }
                  helperText={
                    formOne.touched.designation && formOne.errors.designation
                  }
                />
              </Grid>
              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <TextField
                  required
                  label={FormAttributes.linkedinUrl.label}
                  id={FormAttributes.linkedinUrl.id}
                  className={classes.inputField}
                  fullWidth
                  name="linkedIn"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.linkedIn}
                  error={
                    formOne.touched.linkedIn && Boolean(formOne.errors.linkedIn)
                  }
                  helperText={
                    formOne.touched.linkedIn && formOne.errors.linkedIn
                  }
                />
              </Grid>
              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <TextField
                  required
                  label={FormAttributes.companyWebsite.label}
                  id={FormAttributes.companyWebsite.id}
                  className={classes.inputField}
                  fullWidth
                  name="companyWebsite"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.companyWebsite}
                  error={
                    formOne.touched.companyWebsite &&
                    Boolean(formOne.errors.companyWebsite)
                  }
                  helperText={
                    formOne.touched.companyWebsite &&
                    formOne.errors.companyWebsite
                  }
                />
              </Grid>
              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <TextField
                  label={FormAttributes.officeEmail.label}
                  id={FormAttributes.officeEmail.id}
                  className={classes.inputField}
                  fullWidth
                  name="officeEmail"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.officeEmail}
                  error={
                    formOne.touched.officeEmail &&
                    Boolean(formOne.errors.officeEmail)
                  }
                  helperText={
                    formOne.touched.officeEmail && formOne.errors.officeEmail
                  }
                />
              </Grid>

              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <Autocomplete
                  disablePortal
                  className={classes.inputField}
                  options={companyType}
                  id="typeCompany"
                  onBlur={formOne.handleBlur}
                  onChange={(e, value, reason) =>
                    formOne.setFieldValue("companyType", value)
                  }
                  value={formOne.values.companyType}
                  //   getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={FormAttributes.companyType.label}
                      id={FormAttributes.companyType.id}
                      fullWidth
                      error={
                        formOne.touched.companyType &&
                        Boolean(formOne.errors.companyType)
                      }
                      helperText={
                        formOne.touched.companyType &&
                        formOne.errors.companyType
                      }
                    />
                  )}
                  // value={formState.checklistId}
                  // onChange={(
                  //     event: any,
                  //     newValue: string | null,
                  //     reason: string
                  // ) => {
                  //     handleChange(event, newValue, 'checklistId', reason);
                  // }}
                />
              </Grid>

              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <TextField
                  label={FormAttributes.alternativeEmail.label}
                  id={FormAttributes.alternativeEmail.id}
                  className={classes.inputField}
                  fullWidth
                  name="alternativeEmail"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.alternativeEmail}
                  error={
                    formOne.touched.alternativeEmail &&
                    Boolean(formOne.errors.alternativeEmail)
                  }
                  helperText={
                    formOne.touched.alternativeEmail &&
                    formOne.errors.alternativeEmail
                  }
                />
              </Grid>
              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <Box className={classes.displayFlex}>
                  <Grid
                    item
                    xs={FULL_SIZE_GRID}
                    sm={FULL_SIZE_GRID}
                    md={FULL_SIZE_GRID}
                    lg={FULL_SIZE_GRID}
                    className={classes.limitWidth}
                  >
                    <TextField
                      label={FormAttributes.phoneNumber.label}
                      id={FormAttributes.phoneNumber.id}
                      placeholder="9002299333"
                      fullWidth
                      className={classes.inputField}
                      name="phoneNumber"
                      onBlur={formOne.handleBlur}
                      onChange={formOne.handleChange}
                      value={formOne.values.phoneNumber}
                      error={
                        formOne.touched.phoneNumber &&
                        Boolean(formOne.errors.phoneNumber)
                      }
                      helperText={
                        formOne.touched.phoneNumber &&
                        formOne.errors.phoneNumber
                      }
                    />
                  </Grid>
                </Box>
              </Grid>

              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <TextField
                  label={FormAttributes.landlineNumber.label}
                  id={FormAttributes.landlineNumber.id}
                  className={classes.inputField}
                  fullWidth
                  name="landlineNumber"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.landlineNumber}
                  error={
                    formOne.touched.landlineNumber &&
                    Boolean(formOne.errors.landlineNumber)
                  }
                  helperText={
                    formOne.touched.landlineNumber &&
                    formOne.errors.landlineNumber
                  }
                />
              </Grid>
              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <Typography variant="h5" className={classes.Heading}>
                  Company full address <span className="asterisk-span">*</span>
                </Typography>
              </Grid>

              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                style={{ marginTop: "10px" }}
                className={classes.limitWidth}
              >
                <TextField
                  label={FormAttributes.addressOne.label}
                  id={FormAttributes.addressOne.id}
                  className={classes.inputField}
                  fullWidth
                  name="addressLine1"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.addressLine1}
                  error={
                    formOne.touched.addressLine1 &&
                    Boolean(formOne.errors.addressLine1)
                  }
                  helperText={
                    formOne.touched.addressLine1 && formOne.errors.addressLine1
                  }
                />
              </Grid>
              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <TextField
                  label={FormAttributes.addressTwo.label}
                  id={FormAttributes.addressTwo.id}
                  className={classes.inputField}
                  fullWidth
                  name="addressLine2"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.addressLine2}
                  error={
                    formOne.touched.addressLine2 &&
                    Boolean(formOne.errors.addressLine2)
                  }
                  helperText={
                    formOne.touched.addressLine2 && formOne.errors.addressLine2
                  }
                />
              </Grid>
              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <TextField
                  label={FormAttributes.pincode.label}
                  id={FormAttributes.pincode.id}
                  className={classes.inputField}
                  fullWidth
                  name="pincode"
                  onBlur={formOne.handleBlur}
                  onChange={formOne.handleChange}
                  value={formOne.values.pincode}
                  error={
                    formOne.touched.pincode && Boolean(formOne.errors.pincode)
                  }
                  helperText={formOne.touched.pincode && formOne.errors.pincode}
                />
              </Grid>
              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <TextField
                  required
                  variant="outlined"
                  label={FormAttributes.city.label}
                  fullWidth
                  id={FormAttributes.city.id}
                  name="city"
                  value={formOne.values.city}
                  onBlur={formOne.handleBlur}
                  className={classes.inputField}
                  onChange={formOne.handleChange}
                  error={formOne.touched.city && Boolean(formOne.errors.city)}
                  helperText={formOne.touched.city && formOne.errors.city}
                />
              </Grid>
              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <TextField
                  required
                  variant="outlined"
                  label={FormAttributes.state.label}
                  fullWidth
                  name="state"
                  id={FormAttributes.state.id}
                  className={classes.inputField}
                  onBlur={formOne.handleBlur}
                  value={formOne.values.state}
                  onChange={formOne.handleChange}
                  error={formOne.touched.state && Boolean(formOne.errors.state)}
                  helperText={formOne.touched.state && formOne.errors.state}
                />
              </Grid>
              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                className={classes.limitWidth}
              >
                <Autocomplete
                  disablePortal
                  className={classes.inputField}
                  options={countries}
                  id="selectCountry"
                  onBlur={formOne.handleBlur}
                  onChange={(e, value, reason) =>
                    formOne.setFieldValue("country", value)
                  }
                  value={formOne.values.country}
                  //   getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={FormAttributes.country.label}
                      fullWidth
                      required
                      name="country"
                      id={FormAttributes.country.id}
                      error={
                        formOne.touched.country &&
                        Boolean(formOne.errors.country)
                      }
                      helperText={
                        formOne.touched.country && formOne.errors.country
                      }
                    />
                  )}
                  // value={formState.checklistId}
                  // onChange={(
                  //     event: any,
                  //     newValue: string | null,
                  //     reason: string
                  // ) => {
                  //     handleChange(event, newValue, 'checklistId', reason);
                  // }}
                />
              </Grid>
              <Grid
                item
                xs={FULL_SIZE_GRID}
                sm={FULL_SIZE_GRID}
                md={FULL_SIZE_GRID}
                lg={FULL_SIZE_GRID}
                textAlign="center"
                className={classes.limitWidth}
              >
                {loader ? (
                  <CircularProgress />
                ) : (
                  <Button
                    sx={{
                      width: "250px",
                      height: "42px",
                      borderRadius: "12px",
                      background: PRIMARY_THEME_COLOR,
                    }}
                    variant="contained"
                    type="submit"
                    // onClick={}
                  >
                    Proceed
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        ) : (
          <div className="center-loader">
            <CircularProgress />
          </div>
        )}
      </StyledContainer>
    </ThemeProvider>
  );
};

export default CompanyFormOne;
