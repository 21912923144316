import React, { FC } from "react";
import { Grid, Button, IconButton, Menu, MenuItem } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import "./FormHeader.css";
import KeycloakService from "../../services/KeycloakService";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const FormHeader: FC<any> = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={2} className="text-center-align">
        <img
          src="assets/images/logo 1.png"
          alt="logo"
          className="header-logo-image"
        />
      </Grid>
      <Grid item xs={7} className="text-center-align">
        <div className="navbar-header">
          <Button>Home</Button>
          <Button>Hiring Contests</Button>
          <Button>Employers</Button>
          <Button>Recruiters</Button>
          <Button>About Us</Button>
        </div>
      </Grid>
      <Grid item xs={3} className="tool-bar-grid">
        <div className="toolbar-icon-container">
          <SearchIcon className="tool-bar-icon toolbar-search-icon" />
          <MailOutlineIcon className="tool-bar-icon" />
          <NotificationsNoneIcon className="tool-bar-icon" />
          <AccountCircleIcon
            className="tool-bar-icon"
            onClick={(event) => handleMenu(event)}
          />
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Profile</MenuItem>
            <MenuItem onClick={KeycloakService.doLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </Grid>
    </Grid>
  );
};

export default FormHeader;
