import React, { ReactElement, FC, useEffect } from "react";
import {Button, Checkbox, Grid, 
    TextField, CircularProgress} from '@mui/material';
import '../../App.css';
import KeycloakService from "../../services/KeycloakService";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { specialCharValidation,
        RECRUITMENT_COMPANY, SNACKBAR_ERROR_MSG,
        FREELANCE_RECRUITER, emailValidation, 
        FREELANCE_GROUP, COMPANY_GROUP, 
        SUCCESS_KEY, WARNING_KEY, ERROR_KEY,
        COPYRIGHT_FOOTER, NUMBER_ONLY_REGEX
    } from '../../constants';
import SignupSuccess from "../SignupSuccess/SignupSuccess";
import { 
    ReferencesArray, FormAttributes, 
} from '../../ConstantValues/EntryFormConstants';
import {
    FORM_HEADER, DECLARATION,
    TERMS_OF_USE, PRIVACY_POLICY,
    SUBMIT_TEXT
} from '../../Messages/EntryFormMessages';
import Notification from "../../components/Notification";
import {Item, useStyles} from "../../InternalStyles/EntryFormStyles";
import { CreateUser } from "../../services/UserService";
import {
    FULL_SIZE_GRID, HALF_SIZE_GRID,
    FULL_WIDTH_PERCENT
} from '../../InternalStyles/CommonStyleVariables';
import './EntryForm.css';

const Otp: FC<any> = (props): ReactElement => {

  const classes = useStyles();

  const [company, setCompany] = React.useState(false);
  const [freelance, setFreelance] = React.useState(true);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailId, setEmail] = React.useState("");
  const [reference, setReference] = React.useState("");
  const [errorState, setErrorState] = React.useState("");
  const [otherReference, setOtherReference] = React.useState("");
  const [alternateMobile, setAlternateMobile] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [termsOfUse, setTermsOfUse] = React.useState(false);
  const [finalPage, setFinalPage] = React.useState(false);
  const [loader, setLoader] = React.useState(false);

  const submitValidation = () => {
    if (firstName.length < 3) return FormAttributes.firstName.stateValue;
    if (lastName.length < 1) return FormAttributes.lastName.stateValue;
    if(!emailValidation(emailId)) return FormAttributes.emailId.stateValue;
    if (alternateMobile && alternateMobile.length !== 12) return FormAttributes.alternateMobile.stateValue;
    if(reference === ReferencesArray[ReferencesArray.length -1] && !otherReference) return FormAttributes.otherReference.stateValue;
  }

  const signupUser = async () => {
    const errorInformed = submitValidation();
    if(errorInformed) {
        setErrorState(errorInformed);
        setMessage(`invalid ${errorInformed}`);
        setType(WARNING_KEY);
        setOpen(true);
        return;
    }
    setLoader(true);
    const userPayload = {
        firstName: firstName,
        lastName: lastName,
        userName: props.countryCode + props.mobileNumber,
        mobileNumber: props.countryCode + props.mobileNumber,
        emailId: emailId,
        department: 'dummy',
        userType: company ? RECRUITMENT_COMPANY : FREELANCE_RECRUITER,
        mobile2: alternateMobile,
        channel: reference,
        internalRecruiter: false,
        otherChannel: otherReference,
        groups: [company ? COMPANY_GROUP : FREELANCE_GROUP]
    }
    const singupResponse = await CreateUser(userPayload, false);
    if(singupResponse?.data?.success) {
        setLoader(false);
        setType(SUCCESS_KEY);
        setFinalPage(true);
    } else {
        setLoader(false);
        setMessage(SNACKBAR_ERROR_MSG);
        setType(ERROR_KEY);
        setOpen(true);
    }
  };

  const handleReferenceSelection = (event: SelectChangeEvent) => {
    setReference(event.target.value as string);
  };

  useEffect(() => {
    const emailExists = KeycloakService.getUserEmail();
    if(emailExists) setEmail(emailExists);
  }, [props.mobileNumber]);

  return (
    <div className="form-encapsulate">
        <div className="header-banner-form">
        <div className="base-header">
          <img  src="assets/images/logo 1.png" alt="logo" />
        </div>
        </div>
        <div className="form-card-holder">
              <Notification
                open={open}
                type={type}
                message={message || SNACKBAR_ERROR_MSG}
                setOpen={setOpen}
              />
            <div className="form-body-card">
                {
                    !finalPage ? 
                    <div>
                        <p className="form-body-content"> {FORM_HEADER} </p>
                        <p id="wel-line"></p>
                        <div>
                            <div className="form-icon-items">
                                <Checkbox checked={company} onChange={e => {
                                    setCompany(e.target.checked)
                                    if(e.target.checked) setFreelance(!e.target.checked)
                                }}/>
                                <Checkbox checked={freelance} className="checkbox-right" onChange={e => {
                                    setFreelance(e.target.checked)
                                    if(e.target.checked) setCompany(!e.target.checked)
                                }} />
                            </div>
                            <div className="form-icon-items">
                                <img src={`assets/images/${company ? 'icon_company_dark.png' : 'icon_company_light.png'}`} alt="rc_li" className="recruiter-left-image" />
                                <img src={`assets/images/${freelance ? 'icon_recruiter_dark.svg' : 'icon_recruiter_light.png'}`} alt="fl_li" />
                            </div>
                            <div className="form-icon-items">
                                <div className={!company ? 'change-text-light recruiter-icons' : 'recruiter-icons'}>
                                    <p className="recruiter-top-text">Recruitment</p>
                                    <p className="recruiter-bottom-text">Company</p>
                                </div>
                                <div className={!freelance ? 'change-text-light' : undefined}>
                                    <p className="recruiter-top-text">Freelance</p>
                                    <p className="recruiter-bottom-text">Recruiter</p>
                                </div>
                            </div>
                            <div className="form-body-overlay">
                                <Grid container spacing={1}>
                                    <Grid item xs={HALF_SIZE_GRID}>
                                        <Item>
                                            <TextField
                                                id={FormAttributes.firstName.id}
                                                label={FormAttributes.firstName.label}
                                                inputProps={{ maxLength: FormAttributes.firstName.maxLength}}
                                                className={classes.inputField}
                                                value={firstName}
                                                error={errorState === FormAttributes.firstName.stateValue ? true : false}
                                                onChange={(e) => {
                                                    if(!specialCharValidation(e.target.value)) return false;
                                                    setFirstName(e.target.value)
                                                }}
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={HALF_SIZE_GRID}>
                                        <Item>
                                            <TextField
                                                id={FormAttributes.lastName.id}
                                                label={FormAttributes.lastName.label}
                                                placeholder={FormAttributes.lastName.placeholder}
                                                inputProps={{ maxLength: FormAttributes.lastName.maxLength}}
                                                className={classes.inputField}
                                                value={lastName}
                                                error={errorState === FormAttributes.lastName.stateValue ? true : false}
                                                onChange={(e) => {
                                                    if(!specialCharValidation(e.target.value)) return false;
                                                    setLastName(e.target.value)
                                                }}
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={FULL_SIZE_GRID}>
                                        <Item>
                                            <TextField
                                                id={FormAttributes.emailId.id}
                                                label={FormAttributes.emailId.label}
                                                className={classes.inputField}
                                                inputProps={{ 
                                                    maxLength: FormAttributes.emailId.maxLength, 
                                                    readOnly: KeycloakService.getUserEmail() ? true : false}}
                                                fullWidth
                                                error={errorState === FormAttributes.emailId.stateValue ? true : false}
                                                value={emailId}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Item>
                                        <Item>
                                            <TextField id={FormAttributes.countryCode.id}
                                                className={classes.inputField}
                                                placeholder={FormAttributes.countryCode.placeholder}
                                                inputProps={{ 
                                                    maxLength: FormAttributes.countryCode.maxLength, 
                                                    readOnly: props.countryCode ? true: false}}
                                                sx={{width: '20%', marginRight: '2%'}}
                                                value={props.countryCode}
                                                variant="outlined" />
                                            <TextField
                                                id={FormAttributes.mobileNumber.id}
                                                label={FormAttributes.mobileNumber.label}
                                                sx={{width: '78%'}}
                                                inputProps={{
                                                    maxLength: FormAttributes.mobileNumber.maxLength,
                                                    readOnly: props.mobileNumber ? true : false}}
                                                className={classes.inputField}
                                                fullWidth
                                                value={props.mobileNumber}
                                                />
                                        </Item>
                                        <Item>
                                            <TextField
                                                id={FormAttributes.alternateMobile.id}
                                                label={FormAttributes.alternateMobile.label}
                                                className={classes.inputField}
                                                fullWidth
                                                placeholder={FormAttributes.alternateMobile.placeholder}
                                                inputProps={{ maxLength: FormAttributes.alternateMobile.maxLength}}
                                                value={alternateMobile}
                                                error={errorState === FormAttributes.alternateMobile.stateValue ? true : false}
                                                onChange={(e) => {
                                                    const regex = NUMBER_ONLY_REGEX;
                                                    if(!regex.test(e.target.value) && e.target.value !== '') return false;
                                                    setAlternateMobile(e.target.value)
                                                }}
                                            />
                                        </Item>
                                        <Item>
                                            <FormControl sx={{ width: FULL_WIDTH_PERCENT, padding: 0}}>
                                                <InputLabel id={FormAttributes.reference.id}>
                                                    {FormAttributes.reference.inputLabel}
                                                </InputLabel>
                                                <Select
                                                sx={{borderRadius: '12px'}}
                                                value={reference}
                                                label={FormAttributes.reference.label}
                                                onChange={handleReferenceSelection}
                                                >
                                                    {
                                                        ReferencesArray.map((reference: string) => (
                                                            <MenuItem value={reference}>{reference}</MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Item>
                                        {reference === ReferencesArray[ReferencesArray.length -1] ? 
                                            <Item className="entry-reference-item">
                                                <TextField
                                                    id={FormAttributes.otherReference.id}
                                                    label={FormAttributes.otherReference.label}
                                                    className={classes.inputField}
                                                    inputProps={{ maxLength: FormAttributes.otherReference.maxLength}}
                                                    fullWidth
                                                    error={errorState === FormAttributes.otherReference.stateValue ? true : false}
                                                    multiline
                                                    rows={2}
                                                    value={otherReference}
                                                    onChange={(e) => setOtherReference(e.target.value)}
                                                />
                                            </Item> : null
                                        }
                                    </Grid>
                                </Grid>
                                <div>
                                    <Checkbox checked={termsOfUse} onChange={e => {
                                        setTermsOfUse(e.target.checked)
                                    }}/>
                                    {DECLARATION} <Button>{TERMS_OF_USE}</Button> and <Button>{PRIVACY_POLICY}</Button>
                                </div>
                                <div className="entry-btn-container">
                                    {
                                        loader ? 
                                        <CircularProgress /> :
                                        <Button fullWidth variant="contained" 
                                            onClick={signupUser}
                                        className={(!props.mobileNumber || !firstName || !lastName || !emailId || !termsOfUse || !reference || (company===freelance))
                                            ? 'invalidate-button' : 'validate-button'}
                                        disabled={!props.mobileNumber || !firstName || !lastName || !emailId || !termsOfUse || !reference || (company===freelance)}>
                                            {SUBMIT_TEXT}
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="footer-container">
                            <p className="copyright footer-font">{COPYRIGHT_FOOTER}</p>
                        </div>
                    </div>
                    : <SignupSuccess />
                }
            </div>
        </div>
    </div>
  );
};

export default Otp;