import HttpService from "../services/HttpService";
import KeycloakService from "../services/KeycloakService";
const _axios = HttpService.getAxiosClient();

export const getUserData = async (userName?:string) => {
  let token = '';
  if(userName) token = await KeycloakService.fetchTokenDifferently();
  return _axios
    .get(
      `${
        process.env.REACT_APP_MAIN_SERVER_URL
      }accounts/v1/users?filter-column=userName&filter-value=${userName ?? KeycloakService.getUsername()}`,
      {headers: token ? {Authorization: `Bearer ${token}`} : {}}
    );
};

export const getUserIdApi = async () => {
  try {
    const response = await _axios.get(
      `${
        process.env.REACT_APP_MAIN_SERVER_URL
      }accounts/v1/users?filter-column=userName&filter-value=${KeycloakService.getUsername()}`
    );
    const UserIdApi = response?.data?.data[0].userId;

    return UserIdApi;
  } catch (error) {
    console.log(error);
  }
};

export const UploadFiles = async (body: any) => {
  const formData = new FormData();
  formData.append("file", body.files[0]);
  formData.append("documentPath", body.documentPath);
  formData.append("documentName", body.documentName);
  return _axios
    .post(
      `${
        process.env.REACT_APP_MAIN_SERVER_URL ||
        "https://api.dev.hiringhood.com/"
      }dms/v1/documents`,
      formData,
      {
        headers : {
        'Content-Type': 'multipart/form-data',
        },
        params: {
          documentTypeId: body.documentTypeId
        }
      }
    )
};

export const UpdateUser = async (body: any) => {
  return _axios
    .post(
      `${
        process.env.REACT_APP_MAIN_SERVER_URL ||
        "https://api.dev.hiringhood.com/"
      }accounts/v1/users`,
      body
    )
    .catch((error) => {
      console.log(error);
    });
};

export const CreateUser = async (body: any, tokenExists: boolean) => {
  let token = '';
  if(!tokenExists) token = await KeycloakService.fetchTokenDifferently();
  const payload = {
    processDefinitionKey: "Process_844844770998710300",
    businessKey: body.userName,
    variables: {
      action: "add",
      userData: JSON.stringify(body),
    },
  }
  return _axios
    .post(
      `${
        process.env.REACT_APP_MAIN_SERVER_URL ||
        "https://api.dev.hiringhood.com/"
      }workflow/v1/process/start`,
      payload,
      {headers: token ? {Authorization: `Bearer ${token}`} : {}}
    )
    .catch((error) => {
      console.log(error);
    });
};

export const UserFiles = async (id: string) => {
  return _axios
    .get(
      `${
        process.env.REACT_APP_MAIN_SERVER_URL ||
        "https://api.dev.hiringhood.com/"
      }dms/v1/documents/download`,
      {
        headers : {
        'Content-Type': 'multipart/form-data',
        },
        params: {
          id
        }
      }
    )
};