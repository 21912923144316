import React, { ReactElement, FC, useEffect, useRef } from "react";
import Button from '@mui/material/Button';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import '../../App.css';
import { PageStatus, COPYRIGHT_FOOTER } from '../../constants';
import "./Otp.css";
import {validateOTP} from '../../services/OnboardService';

function useInterval(callback: (() => void) | undefined, delay: number | null | undefined) {
  const savedCallback: any = useRef()

  // Remember the latest callback.
  useEffect(() => {
    if(savedCallback) savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
    if(savedCallback)  savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const INITIAL_COUNT = 180;
const twoDigits = (num: number) => String(num).padStart(2, '0')

const Otp: FC<any> = (props): ReactElement => {

  const [otp, setOTP] = React.useState(new Array(4).fill(''));
  const [invalidOTP, setInvalidOTP] = React.useState(true);
  const [errorOTP, setErrorOTP] = React.useState(false);
  const [secondsRemaining, setSecondsRemaining] = React.useState(INITIAL_COUNT)

  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1)
      } else {
        setInvalidOTP(false)
        setSecondsRemaining(INITIAL_COUNT)
      }
    },
    invalidOTP === true ? 1000 : null,
  )

  const verifyOTP = async () => {
    const verifyResponse = await validateOTP(
      {
        "channel":"mobile",
        "to": '+' + props.mobileNumber,
        "otp": otp.join('')
      }
    ).catch(err => {
      console.log('eeee', err);
      setErrorOTP(true);
    });
    if(verifyResponse?.data?.success) {
      props.setScreen(PageStatus.FORM);
    }
  };

  const getOTPInput = (eventTarget: EventTarget & HTMLInputElement, index: number) => {
    if(Number.isNaN(Number(eventTarget.value))) return false;
    setOTP([...otp.map((val, id) => id===index ? eventTarget.value: val)]);
    if(eventTarget.nextSibling) {
      (eventTarget?.nextSibling as HTMLElement)?.focus();
    }
  }

  const checkBackSpace = (event: any) => {
    if(event.key === 'Backspace' && event.target.previousSibling && event.target.value === '') {
      (event.target?.previousSibling as HTMLElement)?.focus();
    }
  }

  const openSignup = () => {
    props.setOtpScreen(false);
  }

  return (
      <div className="side-content side-content-padding">
        <Button onClick={openSignup} className="otp-back-btn" startIcon={<ArrowBackIosNewOutlinedIcon />}>Back</Button>
        <p className="side-content-otp"> Enter OTP </p>
        <p id="wel-line"></p>
        <p id="wel-sub-txt">
          4 digits OTP has been sent to your mobile
        </p>
        <div className="side-content-body">
            <div id="form-group">
            <div id="input-field-wrap">
                {otp.map((data, index) => {
                  return (
                    <input
                      type="text"
                      maxLength={1}
                      key={index}
                      value={data}
                      onChange={(e) => getOTPInput(e.target, index)}
                      id="password"
                      onKeyDown={e => checkBackSpace(e)}
                      onFocus={e => e.target.select()}
                    />
                  )
                })}
            </div>
          </div>
          <div>
            <p className="invalid-otp-text">{errorOTP ? 'OTP is invalid, please try again' : null}</p>
          </div>
            <div className="verify-btn-container">
                <Button fullWidth variant="contained" 
                onClick={verifyOTP}
                className={otp.join('').length === 4 ? 'validate-button' : 'invalidate-button'}
                disabled={otp.join('').length !== 4}>
                  Verify
                </Button>
            </div>
              <div>
                <p className="otp-timer-text">
                  <Button onClick={() => {
                    props.sendOTP();
                    setInvalidOTP(true);
                    setOTP(new Array(4).fill(''));
                  }} disabled={invalidOTP}>Resend OTP </Button>
                  {'('}{twoDigits(minutesToDisplay)}:{twoDigits(secondsToDisplay)}{')'}</p>
              </div>
            <div className="otp-account-container">
              <span className="account-text"> Already have an account? </span>
              <a href="/" className="signin-text"> Sign in here</a>
            </div>
            <div className="footer-container">
              <footer>
                  <p className="copyright footer-font">{COPYRIGHT_FOOTER}</p>
              </footer>
            </div>
        </div>
      </div>
  );
};

export default Otp;