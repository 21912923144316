import React, { ReactElement, FC, useEffect } from "react";
import {CircularProgress} from '@mui/material';
import FormHeader from './FormHeader/FormHeader';
import OnboardFreelancer from './OboardFreelancer';
import OnboardCompanyUser from './OboardCompanyUser';
import KeycloakService from "../services/KeycloakService";
import { useAppDispatch } from '../services/StoreHooks';
import { useNavigate } from 'react-router-dom';
import { RECRUITMENT_COMPANY, REFERENCE_DATA_FORM,
ReferenceData } from '../constants';
import SignupSuccess from "./SignupSuccess/SignupSuccess";
import { getFormData } from '../services/FormDataService';
import { useAppSelector } from '../services/StoreHooks';
import OnboardInternalRecruiter from "./OnboardInternalRecruiter";
import Notification from "../components/Notification";
import { BACKGROUND_DETAILS_FORM, BILLING_DETAILS_FORM } from "../constants";
import { getUserData } from "../services/UserService";

const OnboardBase: FC<any> = (): ReactElement => {

  const [userId, setUserId] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [complete, setComplete] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [dataMessage, setDataMessage] = React.useState('');
  const [type, setType] = React.useState('');
  const [referenceData, setReferenceData] = React.useState<ReferenceData[]>([]);
  const [backgroundDetails, setBackgroundDetails] = React.useState<any>({});
  const [billingDetails, setBillingDetails] = React.useState<any>({});

  const dispatch = useAppDispatch();

  const userDataState = useAppSelector((state) => state.currentUser);

  useEffect(() => {
    setLoader(true);
    const checkKeycloakActive = async () => {
      let userData
      try {
        userData = await getUserData();
      } catch (err) {
        console.log(err);
        if(KeycloakService.isLoggedIn()) {
          navigate('/signup');
        }
      }
      const referenceData = await getFormData(REFERENCE_DATA_FORM, '', '0', 500);
      if(referenceData?.data?.data) {
        const mapData = referenceData?.data?.data?.content.map((item: any) => {
            return {type: item.formData.type, displayText: item.formData.displayText}
        })
        setReferenceData(() => [...mapData]);
      }
      if(userData?.data?.data.length > 0) {
        dispatch({
          type: 'USER_ADD',
          data: userData?.data?.data[0]
        });
        const fillBackground = await getFormData(BACKGROUND_DETAILS_FORM, userData?.data?.data[0].userId);
        if(fillBackground && fillBackground?.data?.data.length > 0) setBackgroundDetails(
          {
            ...fillBackground?.data?.data[0].formData, 
            id: fillBackground?.data?.data[0].id
          }
        );
        const fillBilling = await getFormData(BILLING_DETAILS_FORM, userData?.data?.data[0]?.userId);
        if(fillBilling && fillBilling?.data?.data.length > 0) setBillingDetails({
          ...fillBilling?.data?.data[0].formData,
          id: fillBilling?.data?.data[0].id
        });
        setLoader(false);
        setUserId(userData?.data?.data[0]?.userId);
      } else {
        navigate('/signup');
      }
    }
    if(KeycloakService.getUsername()) checkKeycloakActive();
  }, [userId]);

  const navigate = useNavigate();

  return (
    <>
    <div className="form-encapsulate">
        <div className="header-banner-form">
        <FormHeader />
        <div className="base-header">
          <p className="form-heading-text">
            {complete ? 'Profile is successfully completed!' : 'Complete your profile'}
          </p>
        </div>
        </div>
        <div className="form-card-holder">
            <div className="form-body-card">
              <div>
              <Notification
                open={open}
                type={type}
                message={dataMessage}
                setOpen={setOpen}
              />
              </div>
                    {
                      complete ? 
                      <SignupSuccess complete={complete} /> :
                      <>
                      {!loader ? 
                        <div>
                          {
                            userDataState.userData.userType === RECRUITMENT_COMPANY ? 
                              <>
                                {
                                  userDataState.userData?.internalRecruiter ? 
                                    <OnboardInternalRecruiter
                                      referenceData={referenceData}
                                      setComplete={setComplete} 
                                      setOpen={setOpen}
                                      setType={setType}
                                      setDataMessage={setDataMessage} 
                                      backgroundDetails={backgroundDetails}
                                    />
                                    : 
                                    <OnboardCompanyUser 
                                      referenceData={referenceData}
                                      setComplete={setComplete} 
                                      setOpen={setOpen}
                                      setType={setType}
                                      setDataMessage={setDataMessage} 
                                      backgroundDetails={backgroundDetails}
                                      billingDetails={billingDetails}
                                    />
                                }
                              </>
                            : 
                              <OnboardFreelancer 
                                referenceData={referenceData}
                                setComplete={setComplete}
                                setOpen={setOpen}
                                setType={setType}
                                setDataMessage={setDataMessage} 
                                backgroundDetails={backgroundDetails}
                                billingDetails={billingDetails}
                              />
                          }
                        </div> : 
                        <div className="base-spinner-container"> 
                          <CircularProgress /> 
                        </div>
                      }
                      </>
                    }
                    <div>
                        <div className="footer-container">
                            {/* <p className="copyright" className="footer-font">{COPYRIGHT_FOOTER}</p> */}
                            {
                              !complete ?
                              <p className="copyright mandatory-text"> * All Mandatory Fields</p>
                              : null
                            }
                        </div>
                    </div>
            </div>
        </div>
    </div>
    </>
  );
};

export default OnboardBase;
