import HttpService from "../services/HttpService";
const _axios = HttpService.getAxiosClient();

export const getFormData = async (formId: string, userId?: string, page?: string, size?: number, token?: string) => {
    return _axios.get(`${process.env.REACT_APP_MAIN_SERVER_URL}form-runtime/v1/form-data?formId=${formId}${userId ? '&filter=formData.userId:'+userId : ''}${page ? '&page='+page : ''}${size ? '&size='+size : ''}`,
    {headers: token ? {Authorization: token} : {}}).catch((error) => {
        console.log(error);
    });
}

export const postFormData = async (bodyPayload: {formId: string, formData: any}) => {
    return _axios.post(`${process.env.REACT_APP_MAIN_SERVER_URL}form-runtime/v1/form-data`,
    bodyPayload).catch((error) => {
        console.log(error);
    });
}

export const getFormModeler = async (formId: string) => {
    return _axios.get(`${process.env.REACT_APP_MAIN_SERVER_URL}form-modeler/v1/forms/${formId}`)
    .catch((error) => {
        console.log(error);
    });
}