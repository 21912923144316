export const FIRSTNAME_MSG = "firstName is required";
export const LASTNAME_MSG = "lastName is required"
export const COUNTRY_CODE_MSG = "Country Code is required";
export const SPECIAL_CHAR_ERR_MSG = "cannot have special characters or numbers";
export const EMAIL_ERR_MSG = "Enter a valid email";
export const EMAIL_REQ_MSG = "Email is required";
export const PHN_ERR_MSG = "Phone number is not valid";
export const PHN_REQ_MSG = "Phone number is required";
export const TEAM_REQ_MSG = "Must have team members";
export const TEAM_MIN_MSG = "Minimum of 1 member";
export const SUBMIT_BTN_MSG = 'Submit All Details';
export const RECRUITER_DETAIL_MSG = 'Recruiter Detail';
export const RECRUITER_ADD_TITLE = 'Add Recruiter Details';
export const ADD_RECRUITER_MSG = 'Please add the list of recruiter logins required by your company';
export const USER_ADD_ERR_MSG = "Something went wrong in adding certain users, please find their details remaining on screen"