import React, { ReactElement, FC, useEffect } from "react";
import FormHeader from "./FormHeader/FormHeader";

const Dashboard: FC<any> = (): ReactElement => {
  return (
    <>
      <div className="form-encapsulate">
        <div className="header-banner-form">
          <FormHeader />
          <div className="base-header">
            <p className="form-heading-text">
              {"Dashboard is under Construction"}
            </p>
          </div>
        </div>
        <div className="form-card-holder"></div>
      </div>
    </>
  );
};

export default Dashboard;
