export const FIRSTNAME_MSG = "firstName is required";
export const LASTNAME_MSG = "lastName is required";
export const MIN_MSG = "Must be Minimum 3 Characters";
export const PINCODE_REQ = "Pincode is required";
export const CITY_REQ = "City is required";
export const STATE_REQ = "State is required";
export const COUNTRY_REQ = "Country is required";
export const COMPANY_REQ = "Company Name is required";
export const ADDR1_REQ = "Address Line One is required";
export const ADDR2_REQ = "Address Line Two is required";
export const PINCODE_ERR_MSG = "Pincode is invalid";
export const COMPANY_DESC_MSG = "companyDescription is required";
export const DESIGNATION_REQ = "designation is required";
export const WEBSITE_REQ = "Company Website is required";
export const LINKEDIN_REQ = "linkedin url is required";
export const SPECIAL_CHAR_ERR_MSG = "cannot have special characters or numbers";
export const EMAIL_ERR_MSG = "Enter a valid email";
export const EMAIL_REQ_MSG = "Email is required";
export const PHN_ERR_MSG = "Phone number is not valid";
export const PHN_REQ_MSG = "Phone number is required";
export const LANDLINE_ERR_MSG = "Must be a proper landline number";
export const SUBMIT_BTN_MSG = 'Submit All Details';
export const RECRUITER_DETAIL_MSG = 'Recruiter Detail';
export const RECRUITER_ADD_TITLE = 'Add Recruiter Details';
export const ADD_RECRUITER_MSG = 'Please add the list of recruiter logins required by your company';
export const USER_ADD_ERR_MSG = "Something went wrong in adding certain users, please find their details remaining on screen"