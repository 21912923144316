import React, { FC, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { Grid, Button, TextField, CircularProgress,
  Typography, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
  specialCharValidation,
  FREELANCE_DETAILS_FORM,
  SNACKBAR_ERROR_MSG,
  FREELANCE_RECRUITER,
  urlPatternValidation,
  emailValidation,
  ReferenceData,
  ICON_ID,
  pincodeValidation,
  SUCCESS_KEY,
  WARNING_KEY,
  ERROR_KEY,
  IMAGE_UPLOAD_ERROR,
  BROWSE_FILE_MSG,
  FILE_SIZE_MSG,
  NUMBER_ONLY_REGEX,
  PROCEED_BTN_TEXT
} from "../../constants";
import { useAppSelector } from "../../services/StoreHooks";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getFormData, postFormData } from "../../services/FormDataService";
import { useDropzone } from "react-dropzone";
import { UploadFiles, UserFiles } from '../../services/UserService';
import { 
  useStyles, 
  Item,
  formLabelsTheme
} from '../../InternalStyles/FreelanceFormOneStyles';
import { ThemeProvider } from '@mui/material/styles';
import { 
  FormAttributes,
  EducationArray
} from '../../ConstantValues/FreelanceFormOneConstants';
import {
  FORM_MAX_WIDTH, AUTO_MARGIN,
  FORM_GRID_SPACING, FULL_SIZE_GRID,
  HALF_SIZE_GRID, FULL_WIDTH_PERCENT,
  DASHED_BORDER, 
} from '../../InternalStyles/CommonStyleVariables';
import './FreelanceForm.css';

const FreelancerFormOne: FC<any> = (props) => {
  const classes = useStyles();

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [emailId, setEmail] = React.useState("");
  const [dob, setDob] = React.useState<Date | null>(null);
  const [gender, setGender] = React.useState("");
  const [addressOne, setAddressOne] = React.useState("");
  const [addressTwo, setAddressTwo] = React.useState("");
  const [pincode, setPincode] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [state, setState] = React.useState("");
  const [city, setCity] = React.useState("");
  const [gotData, setGotData] = React.useState(false);
  const [linkedinUrl, setLinkedinUrl] = React.useState("");
  const [alternateEmailId, setAlternateEmailId] = React.useState("");
  const [education, setEducation] = React.useState("");
  const [mobileNo, setMobileNo] = React.useState("");
  const [dataId, setdataId] = React.useState("");
  const [iconID, setIconID] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  const [errorState, setErrorState] = React.useState("");
  const [alternateMobile, setAlternateMobile] = React.useState("");
  const [countries, setCountries] = React.useState<string[]>([]);
  const [iconImage, setIconImage] = React.useState(false);
  const [freelancerImages, setFreelancerImages] = React.useState<any[]>([]);

  const userDataState = useAppSelector((state) => state.currentUser);

  useEffect(() => {
    const tempCountries = props.referenceData.filter((data: ReferenceData) => data.type === 'country')
      .map((country: ReferenceData) => country.displayText);
    setCountries((country) => [...tempCountries]);
    const fillResponse = props.formOneDetails;
    if (fillResponse.id) {
      setIconID(fillResponse.iconID)
      patchData(fillResponse);
    } else {
      setFirstName(userDataState.userData.firstName);
      setLastName(userDataState.userData.lastName);
      setEmail(userDataState.userData.emailId);
      setMobileNo(userDataState.userData.mobileNumber);
      setAlternateMobile(userDataState.userData?.mobile2 || '');
    }
    const getDataFill = async () => {
      const userFilesData = await UserFiles(iconID);
      const blob = new Blob([userFilesData.data]);
      setFreelancerImages(() => [new File([blob], "name", { type: 'application/png', })]);
      setIconImage(true);
    };
    const getFreelanceFill = async () => {
      const fillResponse = await getFormData(
        FREELANCE_DETAILS_FORM,
        userDataState.userId
      );
      if (fillResponse && fillResponse?.data?.data.length > 0) {
        patchData(fillResponse?.data?.data[0].formData);
      }
    }
    getFreelanceFill();
    if(iconID) getDataFill();
    setGotData(true);
  }, [gotData]);

  const patchData = async (patchObject: any) => {
    setdataId(patchObject.id);
    setCountry(patchObject.address.country);
    setFirstName(patchObject.firstName);
    setCity(patchObject.address.city);
    setLastName(patchObject.lastName);
    setEducation(patchObject.education);
    setGender(patchObject.gender);
    setIconID(patchObject.iconID);
    setState(patchObject.address.state);
    setEmail(patchObject.email);
    setLinkedinUrl(patchObject.linkedIn);
    setAlternateMobile(patchObject.alternateMobile);
    setMobileNo(patchObject.mobile);
    setAlternateEmailId(patchObject.alternateEmailId);
    setAddressOne(patchObject.address.addressLine1);
    if(patchObject.dob)setDob(patchObject.dob);
    setAddressTwo(patchObject.address.addressLine2);
    setPincode(patchObject.address.pincode);
    setDescription(patchObject.companyDescription);
  }
  const submitData = async () => {
    const errorInformed = submitDataValidation();
    if (errorInformed) {
      props.setType(WARNING_KEY);
      props.setDataMessage(`Please fill proper ${errorInformed}`);
      props.setOpen(true);
      setErrorState(errorInformed);
      return;
    }
    setLoader(true);
    let iconDocumentId = '';
    if(acceptedFilesFreelancerPic.length > 0 && !iconID) {
      const uploadResponse = await UploadFiles(uploadPayloadBuild())
      .catch((error) => {
        props.setType(ERROR_KEY);
        props.setDataMessage(IMAGE_UPLOAD_ERROR);
        props.setOpen(true);
        console.log(error);
      });
      if(uploadResponse?.data?.success) {
        setIconID(uploadResponse?.data?.data?.id);
        iconDocumentId = uploadResponse?.data?.data?.id;
      }
    }
    const formPayload = {
      formId: FREELANCE_DETAILS_FORM,
      formData: {
        recruiterType: FREELANCE_RECRUITER,
        companyId: "",
        userId: userDataState.userId,
        alternateEmailId,
        alternateMobile,
        firstName: firstName,
        lastName: lastName,
        companyDescription: description,
        linkedIn: linkedinUrl,
        email: emailId,
        dob: dob,
        iconID: iconDocumentId || iconID,
        gender: gender,
        education: education,
        mobile: mobileNo,
        address: {
          addressLine1: addressOne,
          addressLine2: addressTwo,
          pincode: pincode,
          city: city,
          state: state,
          country: country,
        },
      },
    };
    const formPostResponse = await postFormData(
      dataId ? Object.assign({ ...formPayload, id: dataId }) : formPayload
    );
    if (formPostResponse?.data?.success) {
      props.setType(SUCCESS_KEY);
      props.setDataMessage('Successfuly Submitted Data');
      props.setOpen(true);
      props.setFormOneFilled(true);
      props.handleComplete();
      setLoader(false);
      props.handleNext();
    } else {
      props.setType(ERROR_KEY);
      props.setDataMessage(SNACKBAR_ERROR_MSG);
      props.setOpen(true);
      setLoader(false);
    }
  };

  const uploadPayloadBuild = () => {
    return {
      documentTypeId: ICON_ID,
      documentPath: `icons/${userDataState.userId}`,
      documentName: userDataState.userId,
      files: acceptedFilesFreelancerPic
    }
  }

  const submitDataValidation = () => {
    if (!firstName || firstName.length < 3) return FormAttributes.firstName.stateValue;
    if (!lastName || lastName.length < 1) return FormAttributes.lastName.stateValue;
    if (!linkedinUrl || !urlPatternValidation(linkedinUrl)) return FormAttributes.linkedinUrl.stateValue;
    if (!emailId || !emailValidation(emailId)) return FormAttributes.emailId.stateValue;
    if (alternateEmailId && !emailValidation(alternateEmailId))
      return FormAttributes.alternateEmailId.stateValue;
    if (!gender) return FormAttributes.gender.stateValue;
    if (!education) return FormAttributes.education.stateValue;
    if (mobileNo.length !== 12) return FormAttributes.mobileNumber.stateValue;
    if (alternateMobile && alternateMobile.length !== 12) return FormAttributes.alternateMobile.stateValue;
    if (!addressOne) return FormAttributes.addressOne.stateValue;
    if (!addressTwo) return FormAttributes.addressTwo.stateValue;
    if (!pincode || !pincodeValidation(pincode)) return FormAttributes.pincode.stateValue;
    if (!city) return FormAttributes.city.stateValue;
    if (!country) return FormAttributes.country.stateValue;
    if (!state) return FormAttributes.state.stateValue;
  };

  const {
    acceptedFiles: acceptedFilesFreelancerPic,
    getRootProps: rootPropsFreelancerPic,
    getInputProps: inputPropsFreelancerPic,
    open: openFreelancerPic,
  } = useDropzone({
    disabled: false,
    onDrop: () => {
      setIconImage(false);
      setIconID('');
    }
  });

  return (
    <ThemeProvider theme={formLabelsTheme}>
        <div className="form-body-overlay">
          <Grid container spacing={FORM_GRID_SPACING} className="form-top-grid">
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={HALF_SIZE_GRID} lg={HALF_SIZE_GRID} className={classes.limitWidth}>
              <Item>
                <TextField
                  id={FormAttributes.firstName.id}
                  label={FormAttributes.firstName.label}
                  inputProps={{ maxLength: FormAttributes.firstName.maxLength }}
                  className={classes.inputField}
                  value={firstName}
                  required
                  error={errorState === FormAttributes.firstName.stateValue ? true : false}
                  onChange={(e) => {
                    if (!specialCharValidation(e.target.value)) return false;
                    setFirstName(e.target.value);
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={HALF_SIZE_GRID} lg={HALF_SIZE_GRID} className={classes.limitWidth}>
              <Item>
                <TextField
                  id={FormAttributes.lastName.id}
                  label={FormAttributes.lastName.label}
                  inputProps={{ maxLength: FormAttributes.lastName.maxLength }}
                  className={classes.inputField}
                  error={errorState === FormAttributes.lastName.stateValue ? true : false}
                  value={lastName}
                  required
                  onChange={(e) => {
                    if (!specialCharValidation(e.target.value)) return false;
                    setLastName(e.target.value);
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
                <Item>
                    <TextField
                        multiline
                        rows={4}
                        id={FormAttributes.description.id}
                        label={FormAttributes.description.label}
                        className={classes.inputField}
                        fullWidth
                        inputProps={{ maxLength: FormAttributes.description.maxLength }}
                        value={description}
                        onChange={(e) => {
                          const regex = /^[a-zA-Z '&().,\b]+$/;
                          if(!regex.test(e.target.value) && e.target.value !== '') return false;
                          setDescription(e.target.value);
                        }}
                    />
                </Item>
            </Grid>
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
              <p className="freelance-upload-text">Upload Your Image</p>
                <Paper
                    sx={{
                    borderRadius: 4,
                    }}
                    variant="outlined"
                >
                    <Box
                    sx={{ border: DASHED_BORDER, p: 3, m: 3 }}
                    {...rootPropsFreelancerPic({ className: "dropzone" })}
                    >
                    <input {...inputPropsFreelancerPic()} />
                    <Typography variant="body1" color="blue" textAlign="left">
                        Drag & drop{" "}
                        <Typography fontSize={20} color="blue" textAlign="right">
                        +
                        </Typography>
                    </Typography>
                    <Typography>Your file here or browse</Typography>
                    <aside>
                        {
                          iconImage ? 
                          freelancerImages.map((file: any) => (
                            <li key={file.path || file.name}>
                              {file.path || iconID} - {file.size} bytes
                            </li>
                          )) : 
                          acceptedFilesFreelancerPic.map((file: any) => (
                            <li key={file.path || file.name}>
                              {file.path || iconID} - {file.size} bytes
                            </li>
                          ))
                        }
                    </aside>
                    </Box>
                </Paper>
                <Box
                    sx={{
                    display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                    justifyContent: "space-between",
                    p: 2,
                    mb: 3
                    }}
                    className={classes.limitWidth}
                >
                    <Box>
                    <Box
                        textAlign="left"
                        onClick={openFreelancerPic}
                        className={classes.browseFiles}
                    >
                        {BROWSE_FILE_MSG}
                    </Box>
                    <Box>{FILE_SIZE_MSG}</Box>
                    </Box>

                    <Box
                    textAlign="right"
                    onClick={openFreelancerPic}
                    className={classes.uploadLogoText}
                    >
                    Upload Logo
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
              <Item>
                <TextField
                  id={FormAttributes.linkedinUrl.id}
                  label={FormAttributes.linkedinUrl.label}
                  className={classes.inputField}
                  inputProps={{ maxLength: FormAttributes.linkedinUrl.maxLength }}
                  fullWidth
                  required
                  error={errorState === FormAttributes.linkedinUrl.stateValue ? true : false}
                  value={linkedinUrl}
                  onChange={(e) => setLinkedinUrl(e.target.value)}
                />
              </Item>
            </Grid>
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
              <Item>
                <TextField
                  id={FormAttributes.emailId.id}
                  label={FormAttributes.emailId.label}
                  className={classes.inputField}
                  required
                  inputProps={{
                    maxLength: FormAttributes.emailId.maxLength,
                    readOnly: userDataState.userData.emailId ? true : false,
                  }}
                  error={errorState === FormAttributes.emailId.stateValue ? true : false}
                  fullWidth
                  value={emailId}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Item>
            </Grid>
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
              <Item>
                <TextField
                  id={FormAttributes.alternateEmailId.id}
                  label={FormAttributes.alternateEmailId.label}
                  className={classes.inputField}
                  inputProps={{ maxLength: FormAttributes.alternateEmailId.maxLength }}
                  fullWidth
                  error={errorState === FormAttributes.alternateEmailId.stateValue ? true : false}
                  value={alternateEmailId}
                  onChange={(e) => setAlternateEmailId(e.target.value)}
                />
              </Item>
            </Grid>
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
              <Item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableFuture
                    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 17))}
                    label="Date of birth"
                    value={dob}
                    onChange={(e) => setDob(e)}
                    renderInput={(params) => (
                      <TextField className={classes.inputField} {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Item>
            </Grid>
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
              <Item>
                <FormControl className="freelance-select-control">
                  <InputLabel id="demo-select-small">{FormAttributes.gender.label}<span className="asterisk-span"> *</span></InputLabel>
                  <Select
                    className="freelance-select"
                    value={gender}
                    label={FormAttributes.gender.label}
                    error={errorState === FormAttributes.gender.stateValue ? true : false}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                  </Select>
                </FormControl>
              </Item>
            </Grid>
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
              <Item>
                <FormControl className="freelance-select-control">
                  <InputLabel id="demo-select-small">{FormAttributes.education.label}<span className="asterisk-span"> *</span></InputLabel>
                  <Select
                    className="freelance-select"
                    value={education}
                    label={FormAttributes.education.label}
                    error={errorState === FormAttributes.education.stateValue ? true : false}
                    onChange={(e) => setEducation(e.target.value)}
                  >
                    {
                      EducationArray.map(education => (
                        <MenuItem key={education} value={education}>{education}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Item>
            </Grid>
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
              <Item>
                <TextField
                  id={FormAttributes.mobileNumber.id}
                  label={FormAttributes.mobileNumber.label}
                  inputProps={{
                    maxLength: FormAttributes.mobileNumber.maxLength,
                    readOnly: userDataState.userData.mobileNumber
                      ? true
                      : false,
                  }}
                  className={classes.inputField}
                  fullWidth
                  error={errorState === FormAttributes.mobileNumber.stateValue ? true : false}
                  value={mobileNo}
                  placeholder={FormAttributes.mobileNumber.placeholder}
                  onChange={(e) => {
                    const regex = NUMBER_ONLY_REGEX;
                    if (!regex.test(e.target.value) && e.target.value !== '') return false;
                    setMobileNo(e.target.value);
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
              <Item>
                <TextField
                  id={FormAttributes.alternateMobile.id}
                  label={FormAttributes.alternateMobile.label}
                  className={classes.inputField}
                  fullWidth
                  placeholder={FormAttributes.alternateMobile.placeholder}
                  error={errorState === FormAttributes.alternateMobile.stateValue ? true : false}
                  inputProps={{ maxLength: FormAttributes.alternateMobile.maxLength }}
                  value={alternateMobile}
                  onChange={(e) => {
                    const regex = NUMBER_ONLY_REGEX;
                    if (!regex.test(e.target.value) && e.target.value !== '') return false;
                    setAlternateMobile(e.target.value);
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
              <p className="freelance-title-text">
                Address Details
              </p>
              <Item>
                <TextField
                  id={FormAttributes.addressOne.id}
                  label={FormAttributes.addressOne.label}
                  className={classes.inputField}
                  inputProps={{ maxLength: FormAttributes.addressOne.maxLength }}
                  fullWidth
                  required
                  error={errorState === FormAttributes.addressOne.stateValue ? true : false}
                  value={addressOne}
                  onChange={(e) => setAddressOne(e.target.value)}
                />
              </Item>
            </Grid>
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
              <Item>
                <TextField
                  id={FormAttributes.addressTwo.id}
                  label={FormAttributes.addressTwo.label}
                  className={classes.inputField}
                  inputProps={{ maxLength: FormAttributes.addressTwo.maxLength }}
                  fullWidth
                  required
                  error={errorState === FormAttributes.addressTwo.stateValue ? true : false}
                  value={addressTwo}
                  onChange={(e) => setAddressTwo(e.target.value)}
                />
              </Item>
            </Grid>
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
              <Item>
                <TextField
                  id={FormAttributes.pincode.id}
                  label={FormAttributes.pincode.label}
                  className={classes.inputField}
                  inputProps={{ maxLength: FormAttributes.pincode.maxLength }}
                  fullWidth
                  error={errorState === FormAttributes.pincode.stateValue ? true : false}
                  value={pincode}
                  required
                  onChange={(e) => {
                    const regex = NUMBER_ONLY_REGEX;
                    if (!regex.test(e.target.value) && e.target.value !== '') return false;
                    setPincode(e.target.value)
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
              <Item>
                <TextField
                  id={FormAttributes.city.id}
                  className={classes.inputField}
                  inputProps={{ maxLength: FormAttributes.city.maxLength }}
                  fullWidth
                  required
                  value={city}
                  error={errorState === FormAttributes.city.stateValue ? true : false}
                  label={FormAttributes.city.label}
                  onChange={(e) => {
                    if (!specialCharValidation(e.target.value)) return false;
                    setCity(e.target.value)
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
              <Item>
                <TextField
                  id={FormAttributes.state.id}
                  className={classes.inputField}
                  inputProps={{ maxLength: FormAttributes.state.maxLength }}
                  fullWidth
                  value={state}
                  label={FormAttributes.state.label}
                  required
                  error={errorState === FormAttributes.state.stateValue ? true : false}
                  onChange={(e) => {
                    if (!specialCharValidation(e.target.value)) return false;
                    setState(e.target.value)
                  }}
                />
              </Item>
            </Grid>
            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
              <Item>
                <FormControl className="freelance-select-control">
                  <InputLabel id="demo-select-small">
                    {FormAttributes.country.label}<span className="asterisk-span"> *</span>
                  </InputLabel>
                  <Select
                    className="freelance-select"
                    value={country}
                    error={errorState === FormAttributes.country.stateValue ? true : false}
                    label={FormAttributes.country.label}
                    onChange={(e) => setCountry(e.target.value)}
                  > 
                    {
                      countries?.map((item) => (
                        <MenuItem value={item} key={item}>{item}</MenuItem>
                      ))
                    }
                    
                  </Select>
                </FormControl>
              </Item>
            </Grid>
          </Grid>
          <div className="freelance-btn-grid">
            <Grid container spacing={1}>
              <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
                <Item>
                  {
                    loader ? 
                      <CircularProgress /> :
                      <Button
                        variant="contained"
                        onClick={submitData}
                        className="radius-button proceed-button"
                        fullWidth
                      >
                        {PROCEED_BTN_TEXT}
                      </Button>
                  }
                </Item>
              </Grid>
            </Grid>
          </div>
        </div>
    </ThemeProvider>
  );
};

export default FreelancerFormOne;
