import React, { ReactElement, FC } from "react";
import {Button, Snackbar} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TextField, CircularProgress } from "@mui/material";
import '../../App.css';
import Otp from '../Otp/Otp';
import { PageStatus } from '../../constants';
import EntryFrom from '../EntryForm/EntryForm';
import {
  SNACKBAR_ERROR_MSG, COPYRIGHT_FOOTER,
  NUMBER_ONLY_REGEX, OTP_SEND_PAYLOAD
} from '../../constants';
import './Signup.css';
import {generateOTP} from '../../services/OnboardService';
import {getUserData} from '../../services/UserService';

const useStyles = makeStyles(() => ({
  inputField: {
     [`& fieldset`]: {
           borderRadius: 12,
     },
  },
}));

const Signup: FC<any> = (): ReactElement => {

  const classes = useStyles();

  const [countryCode, setCountryCode] = React.useState('');
  const [mobileNumber, setMobileNumber] = React.useState('');
  const [otpScreen, setOtpScreen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [invalidInput, setInvalidInput] = React.useState(false);
  const [screen, setScreen] = React.useState<PageStatus>(PageStatus.SIGNUP);
  const [existingUser, setExistingUser] = React.useState('');

  const sendOTP = async () => {
    const validMobile = !Number.isNaN(Number(mobileNumber)) && mobileNumber.length === 10;
    // const validEmail = emailValidation(mobileNumber);
    if(!validMobile
      //  && !validEmail
    ) {
        setInvalidInput(true);
        return
    } else setInvalidInput(false);
    const userData = await getUserData(countryCode + mobileNumber);
    if(userData?.data?.data[0]?.id) {
      setExistingUser("User already Exists, try another number");
      setInvalidInput(true);
      return
    };
    setLoader(true);
    const otpPayload = Object.assign({
      ...OTP_SEND_PAYLOAD, 
      to: '+' + countryCode + mobileNumber
    });
    const otpSentResponse = await generateOTP(otpPayload).catch(err => {
        setLoader(false);
        setOpen(true);
        setCountryCode('');
        setMobileNumber('');
        console.log('eeee', err);
      });
    if(otpSentResponse?.data?.success) {
      setLoader(false);
      setOtpScreen(true)
    };
  }

  return (
  <>
  {
    screen === PageStatus.SIGNUP ? 
    <div className="main-body-content">
      <Snackbar
        open={open}
        autoHideDuration={3000}
        message={SNACKBAR_ERROR_MSG}
        onClose={() => setOpen(false)}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      />
      <div className="signup-content-container">
        <div className="side-image">
        <div className="signup-logo-container">
          <img  src="assets/images/logo 1.png" alt="logo" />
        </div>
        <div className="signup-center-items">
          <img src="assets/images/side-bg.png" alt="logo"/>
        </div>
        </div>
        {!otpScreen ? 
              <div className="side-content">
              <div className="side-content-body">
                  <p id="side-content-body-head">Sign Up</p>
                  <p id="">Sign up your account with</p>
                  <div className="text-input">
                      <TextField id="cnty-code" 
                      size="small" 
                      className={classes.inputField}
                      placeholder="91"
                      inputProps={{ maxLength: 5}}
                      sx={{width: '20%', marginRight: '2%'}}
                      value={countryCode}
                      onChange={e => {
                        const regex = NUMBER_ONLY_REGEX;
                        if(!regex.test(e.target.value) && e.target.value !== '') return false;
                        setCountryCode(e.target.value)
                      }}
                      variant="outlined" />
                      <TextField
                        id="name"
                        label="Mobile Number"
                        className={classes.inputField}
                        inputProps={{ maxLength: 10 }}
                        sx={{width: '78%'}}
                        size="small"
                        error={invalidInput}
                        value={mobileNumber}
                        onChange={(e) => {
                          if(existingUser) setExistingUser('');
                          if(invalidInput) setInvalidInput(false);
                          if(Number.isNaN(Number(e.target.value))) return false;
                          setMobileNumber(e.target.value)
                        }}
                      />
                      {
                        invalidInput ? 
                        <p className="invalid-input">
                          { existingUser || 'Invalid Mobile Number'}
                        </p> : null
                      }
                  </div>
                  <div className="text-center-align">
                      {
                        loader ? 
                          <CircularProgress />
                        : <Button fullWidth variant="contained" 
                            onClick={sendOTP}
                            className={(!mobileNumber || !countryCode) ? 'invalidate-button' : 'validate-button'}
                            disabled={!mobileNumber || !countryCode}>
                              Send OTP
                          </Button>
                      }
                  </div>
                  <div className="account-container">
                    <span className="account-text"> Already have an account? </span>
                    <a href="/" className="signin-text"> Sign in here</a>
                  </div>
                  <div className="footer-container">
                    <footer>
                        <p className="copyright footer-font">{COPYRIGHT_FOOTER}</p>
                    </footer>
                  </div>
              </div>
            </div>
          // : screen === PageStatus.TYPE ? 
          // <UserType setScreen={setScreen} setUserType={setUserType} />
          // : otpScreen ? 
          : <Otp setScreen={setScreen} setOtpScreen={setOtpScreen} mobileNumber={countryCode + mobileNumber} sendOTP={sendOTP}></Otp>
        }
      </div>
    </div>
    : screen === PageStatus.FORM ?
    <EntryFrom mobileNumber={mobileNumber} countryCode={countryCode} />
    : null
  }
  </>
  );
};

export default Signup;