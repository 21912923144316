export enum PageStatus {
  SIGNUP = "signup",
  OTP = "otp",
  TYPE = "error",
  FORM = "form",
}

export interface ReferenceData {
  type: string;
  displayText: string;
}

export const OTP_SEND_PAYLOAD = {
    "channel":"mobile",
    "to": '',
    "from":"91770923418",
    "subject":"HIRINGHOOD APPLICATION",
    "body":"Dear HIRINGHOOD user, please find your otp : ${otp}",
}

export const SUCCESS_KEY = 'success';
export const WARNING_KEY = 'warning';
export const ERROR_KEY = 'error';

export const BACK_BTN_TEXT = 'Go Back';
export const PROCEED_BTN_TEXT = 'Proceed';
export const SKIP_BTN_TEXT = 'Skip';
export const BROWSE_FILE_MSG = 'Browse File';
export const FILE_SIZE_MSG = 'Size: 5MB, png, pdf, jpg.';

export const COMPANY_GROUP = 'HH_COMPANY';
export const FREELANCE_GROUP = 'HH_FREELANCE_RECRUITER';
export const INTERNAL_GROUP = 'HH_COMPANY_RECRUITER';

export const SNACKBAR_ERROR_MSG =
  "Something went wrong, kindly retry with valid details";
export const FORM_SUBMISSION_SUCCESS = "Details are successfully submitted";
export const IMAGE_UPLOAD_ERROR =
  "Something went wrong in image upload, please try again"

export const RECRUITMENT_COMPANY = "Recruitment Company";
export const FREELANCE_RECRUITER = "Freelance Recruiter";

export const FREELANCE_DETAILS_FORM = "978871947496652800";
export const BILLING_DETAILS_FORM = "978866420301561856";
export const COMPANY_DETAILS_FORM = "978864225803960320";
export const BACKGROUND_DETAILS_FORM = "978868992345243648";
export const REFERENCE_DATA_FORM = "982200443870511104";
export const CONTEST_FORM = "987206209585152000";

export const ICON_ID = '986601044146671616';
export const CERTIFICATE_ID = '986601315039989760';

export const COPYRIGHT_FOOTER = '© 2021 Hiringhood, All Rights Reserved';

export const emailValidation = (email: string) => {
  const regex = new RegExp(
    "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
  );
  return !(!email || regex.test(email) === false);
};

export const specialCharValidation = (input: string) => {
  const regex = /^[a-zA-Z \b]+$/;
  return !input || !regex.test(input) === false;
};

export const urlPatternValidation = (url: string) => {
  const regex = new RegExp(
    "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
  );
  return regex.test(url);
};

export const pincodeValidation = (pincode: string) => {
  const regex = /^[1-9]{1}[0-9]{2}[0-9]{3}$/
  return !pincode || !regex.test(pincode) === false; 
}

export const ifscValidation = (ifsc: string) => {
  const regex = /^[A-Z]{4}0[A-Z0-9]{6}$/
  return !ifsc || !regex.test(ifsc) === false
}

export const accountNumValidation = (accNo: string) => {
  const regex = /^\d{9,18}$/
  return !accNo || !regex.test(accNo) === false
} 

export const aadharNumValidation = (aadhar: string) => {
  const regex = /^[2-9]{1}[0-9]{11}$/
  return !aadhar || !regex.test(aadhar) === false
}

export const panNumValidation = (pan: string) => {
  const regex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/
  return !pan || !regex.test(pan) === false
}

export const gstNumValidation = (gst: string) => {
  const regex = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[1-9A-Z]{1}/
  return !gst || !regex.test(gst) === false
}

export const tanNumValidation = (tan: string) => {
  const regex = /[A-Z]{4}[0-9]{5}[A-Z]{1}/
  return !tan || !regex.test(tan) === false
}

export const NUMBER_ONLY_REGEX = /^[0-9\b]+$/
export const LETTERS_ONLY_REGEX = /^[a-zA-Z \b]+$/
export const ALPHA_NUMERIC_REGEX = /^[0-9a-zA-Z \b]+$/
export const ALPHA_NUMERIC_SPECIALCHARS_REGEX = /^[ A-Za-z0-9_@./#&+-]*$/