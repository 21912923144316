import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import KeycloakService from './services/KeycloakService';
import HttpService from './services/HttpService';
import Signup from './pages/Signup/Signup';
import { BrowserRouter } from 'react-router-dom';

const locArr = JSON.stringify(window.location.href).slice(0, -1).split('/');
if(locArr.findIndex(data => data === 'signup') !== -1) {
  ReactDOM.render(
    <React.StrictMode>
      <Signup />
    </React.StrictMode>,
    document.getElementById('root')
  )
} else {
  const renderApp = () => ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
  )
  KeycloakService.initKeycloak(renderApp);
  HttpService.configure();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();