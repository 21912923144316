import React, { FC, useEffect } from "react";
import { Grid, Button, TextField, Autocomplete, Chip,
  Checkbox, Box, Typography, CircularProgress, Radio,
  RadioGroup, FormControlLabel, chipClasses} from "@mui/material";
import FormControl from '@mui/material/FormControl';
import {
    BACKGROUND_DETAILS_FORM, ReferenceData, 
    SUCCESS_KEY, ERROR_KEY, WARNING_KEY,
    SNACKBAR_ERROR_MSG, FREELANCE_RECRUITER, 
    RECRUITMENT_COMPANY, CERTIFICATE_ID,
    BACK_BTN_TEXT, PROCEED_BTN_TEXT,
    IMAGE_UPLOAD_ERROR, BROWSE_FILE_MSG,
    FORM_SUBMISSION_SUCCESS,
    FILE_SIZE_MSG
} from '../../constants';
import { useAppSelector } from '../../services/StoreHooks';
import { getFormData, postFormData } from '../../services/FormDataService';
import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';
import {PrettoSlider} from '../StepIcons';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDropzone } from "react-dropzone";
import { UploadFiles, UserFiles } from "../../services/UserService";
import CloseIcon from '@mui/icons-material/Close';
import {
    customStyles, 
    useStyles, 
    ButtonItem, 
    Item
} from '../../InternalStyles/BackgroundDetailsStyles';
import {
    marks, buttonOptions, CITY_FILTER,
    ROLE_FILTER, SKILL_FILTER, TALENT_FILTER,
    REQUIREMENTS_FILTER, ALL_FILTER, OTHER_FILTER
} from '../../ConstantValues/BackgroundDetailsConstants';
import {
    SKILLSET_MSG,
    VALIDATION_MSG,
    INDUSTRIES_MSG,
    RECRUITERS_NUM_MSG,
    FREELANCE_TENURE_MSG,
    RECRUITMENT_DRIVES_MSG,
    COMPANY_REQUISITION_MSG,
    RECRUITMENT_CERTIFICATIONS_MSG,
    LEVELS_VALIDATION_MSG,
    SKILLS_VALIDATION_MSG,
    INDUSTRIES_VALIDATION_MSG,
    REQUIREMENT_VALIDATION_MSG
} from '../../Messages/BackgroundDetailsMessages';
import {
    FORM_MAX_WIDTH, AUTO_MARGIN,
    FORM_GRID_SPACING, FULL_SIZE_GRID,
    HALF_SIZE_GRID, PRIMARY_COLOR,
    OFF_WHITE_COLOR, PRIMARY_THEME_COLOR,
    FULL_WIDTH_PERCENT, 
} from '../../InternalStyles/CommonStyleVariables';
import './BackgroundDetails.css';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />; 

const BackgroundDetails: FC<any> = (props) => {

    const classes = useStyles();

    const [roles, setRoles] = React.useState<string[]>([]);
    const [functionalRoles, setFunctionalRoles] = React.useState<string[]>([]);
    const [levels, setLevels] = React.useState<string[]>([]);
    const [selectLevels, setSelectLevels] = React.useState<string[]>([]);
    const [locations, setLocations] = React.useState<string[]>([]);
    const [selectLocations, setSelectLocations] = React.useState<string[]>([]);
    const [requirements, setRequirements] = React.useState<string[]>([]);
    const [selectRequirements, setSelectRequirements] = React.useState<string[]>([]);
    const [industries, setIndustries] = React.useState<any[]>([]);
    const [topIndustries, setTopIndustries] = React.useState<any[]>([]);
    const [topFiveSkills, setTopFiveSkills] = React.useState<any[]>([]);
    const [topSkillsArray, setTopSkillsArray] = React.useState<any[]>([]);
    const [gotData, setGotData] = React.useState(false);
    const [drives, setDrives] = React.useState<boolean>(false);
    const [certification, setCertification] = React.useState<boolean>(false);
    const [domesticTenure, setDomesticTenure] = React.useState<number | number[]>(0);
    const [freelanceTenure, setFreelanceTenure] = React.useState<number | number[]>(0);
    const [recruiterCount, setRecruiterCount] = React.useState<number>(0);
    const [placementCount, setPlacementCount] = React.useState<number>(0);
    const [dataId, setdataId] = React.useState("");
    const [loader, setLoader] = React.useState(false);
    const [jobType, setJobType] = React.useState("Full Time");
    const [certificateID, setCertificateID] = React.useState("");
    const [certificationsFile, setCertificationsFile] = React.useState(false);
    const [recruiterFiles, setRecruiterFiles] = React.useState<any[]>([]);

    const userDataState = useAppSelector((state) => state.currentUser);
    const isCompany = userDataState.userData.userType === RECRUITMENT_COMPANY;

    useEffect(() => {
        filterReferenceData();
        const fillResponse = props.backgroundDetails;
        if(fillResponse.id) {
            setCertificateID(fillResponse.certificateID);
            patchFormData(fillResponse);
        }
        const getDataFill = async () => {
            const userFilesData = await UserFiles(certificateID);
            const blob = new Blob([userFilesData.data]);
            setRecruiterFiles(() => [new File([blob], "name", { type: 'application/png' })]);
            setCertificationsFile(true);
        }
        const fillFormBackground = async () => {
            const fillBackground = await getFormData(BACKGROUND_DETAILS_FORM, userDataState.userId);
            if(fillBackground && fillBackground?.data?.data.length > 0) patchFormData({
                ...fillBackground?.data?.data[0].formData,
                id: fillBackground?.data?.data[0].id
            });
        }
        fillFormBackground();
        if(certificateID) getDataFill();
        setGotData(true);
    }, [gotData]);

    const filterReferenceData = () => {
        const tempTopSkillsArray = props.referenceData
            .filter((data: ReferenceData) => data.type === SKILL_FILTER)
            .map((data: ReferenceData) => {
                return {value: data.displayText, label: data.displayText}
            });
        const tempFunctionalRoles = props.referenceData
            .filter((data: ReferenceData) => data.type === ROLE_FILTER);
        const tempLocations = props.referenceData
            .filter((data: ReferenceData) => data.type === CITY_FILTER)
            .map((data: ReferenceData) => data.displayText);
        const tempRequirements = props.referenceData
            .filter((data: ReferenceData) => data.type === REQUIREMENTS_FILTER)
            .map((data: ReferenceData) => data.displayText);
        const tempLevels = props.referenceData
            .filter((data: ReferenceData) => data.type === TALENT_FILTER)
            .map((data: ReferenceData) => data.displayText);
        setLevels((data) => [...tempLevels]);
        setLocations((data) => [...tempLocations]);
        setRequirements((data) => [...tempRequirements]);
        setTopSkillsArray((data) => [...tempTopSkillsArray]);
        setFunctionalRoles((data) => [...tempFunctionalRoles.map(
            (data: ReferenceData) => data.displayText)]
        );
        setIndustries((data) => [...tempFunctionalRoles.map((data: ReferenceData) => {
            return {value: data.displayText, label: data.displayText}
        })]);
    }

    const patchFormData = (patchObject: any) => {
        setdataId(patchObject.id);
        setJobType(patchObject.jobType);
        setDrives(patchObject.drivesHandled);
        setRecruiterCount(patchObject.recruiterCount)
        setPlacementCount(patchObject.lastYearPlacementCount);
        setCertification(patchObject.certification);
        setCertificateID(patchObject.certificateID);
        setDomesticTenure(patchObject.domesticTenure);
        setFreelanceTenure(patchObject.freelanceTenure);
        setRoles((role) => [...patchObject.functionalRoles]);
        setSelectLevels((level) => [...patchObject.levelsHandled]);
        setSelectLocations((location) => [...patchObject.jobLocations]);
        setSelectRequirements((requirement) => [...patchObject.requirementsHandled])
        setTopFiveSkills((skill) => [...patchObject.skillSets.map(
            (skill:string) => {
                return {value: skill, label: skill}
            }
        )]);
        setTopIndustries((industry) => [...patchObject.industriesExpertise.map(
            (industry: string) => {
                return {value: industry, label: industry}
            }
        )]);
    }

    const handleFunctionalroles = (event: any, value: any) => {
        if(roles.length < 5) setRoles(value);
    };

    const handleRequirements = (event: any, value: any) => {
        if(value.includes(ALL_FILTER)) {
            const filteredRequirements = requirements.filter(requirement => requirement !== OTHER_FILTER);
            setSelectRequirements(filteredRequirements)
        } else if(selectRequirements.includes(ALL_FILTER)) {
            setSelectRequirements([]);
        } else setSelectRequirements(value);
    };

    const handleLocations = (event: any, value: any) => {
        setSelectLocations(value);
    };

    const handleRemoveChip = (item: string, itemArray: string[]) => {
        const index = itemArray.findIndex(elem => elem === item);
        if(index !== -1) {
            itemArray.splice(index, 1);
            return itemArray
        }
    }

    const handleLevels = (event: any, value: any) => {
        if(value.includes(ALL_FILTER)) {
            const filteredLevels = levels.filter(level => level !== OTHER_FILTER);
            setSelectLevels(filteredLevels)
        } else if(selectLevels.includes(ALL_FILTER)) {
            setSelectLevels([]);
        } else setSelectLevels(value);
    };

    const handleTopSkills = (
        newValue: OnChangeValue<{value: string, label: string, isFixed?: boolean, isDisabled?: boolean}, true>,
        actionMeta: ActionMeta<{value: string, label: string, isFixed?: boolean, isDisabled?: boolean}>
      ) => {
        setTopFiveSkills(ar => [...newValue]);
    };

    const handleTopIndustries = (
        newValue: OnChangeValue<{value: string, label: string, isFixed?: boolean, isDisabled?: boolean}, true>,
        actionMeta: ActionMeta<{value: string, label: string, isFixed?: boolean, isDisabled?: boolean}>
      ) => {
        if(topIndustries.length < 5) setTopIndustries(ar => [...newValue]);
    };

    const submitData = async () => {
        const errorInformed = submitDataValidation();
        if(errorInformed) {
            props.setDataMessage(`${VALIDATION_MSG} ${errorInformed}`);
            props.setType(WARNING_KEY);
            props.setOpen(true);
            return;
        }
        setLoader(true);
        let certificateId = '';
        if(certification && acceptedFilesCertificates.length > 0 && !certificateID) {
            const uploadResponse = await UploadFiles(uploadPayloadBuild())
            .catch((error) => {
              props.setOpen(true);
              props.setType(ERROR_KEY);
              props.setDataMessage(IMAGE_UPLOAD_ERROR);
              console.log(error);
            });
            if(uploadResponse?.data?.success) {
                certificateId = uploadResponse?.data?.data?.id;
            }
        }
        const formPayload = {
            "formId": BACKGROUND_DETAILS_FORM,
            "formData": {
                userId: userDataState.userId,
                companyId: userDataState.userData.companyId,
                relatedToType: userDataState.userData.userType === FREELANCE_RECRUITER ?
                FREELANCE_RECRUITER : RECRUITMENT_COMPANY,
                relatedTo: {
                    recruiterId: userDataState.userId,
                    companyId: userDataState.userData.companyId
                },
                jobType,
                drivesHandled: drives,
                lastYearPlacementCount: placementCount,
                certification,
                certificateID: certificateId || certificateID,
                domesticTenure,
                freelanceTenure,
                recruiterCount,
                functionalRoles: roles,
                levelsHandled: selectLevels,
                jobLocations: selectLocations,
                requirementsHandled: selectRequirements,
                skillSets: topFiveSkills.map(data => data.value),
                industriesExpertise: topIndustries.map(data => data.value),
            }
        }
        const formPostResponse = await postFormData(dataId ? Object.assign({...formPayload, id:dataId}) : formPayload);
        if(formPostResponse?.data?.success) {
            props.setOpen(true);
            props.setType(SUCCESS_KEY)
            props.setDataMessage(FORM_SUBMISSION_SUCCESS);
            props.handleComplete();
            setLoader(false);
            props.handleNext();
        } else {
            props.setOpen(true);
            props.setType(ERROR_KEY);
            props.setDataMessage(SNACKBAR_ERROR_MSG);
            setLoader(false);
        };
    }

    const submitDataValidation = () => {
        if(selectRequirements.length < 1) return REQUIREMENT_VALIDATION_MSG;
        if(selectLevels.length < 1) return LEVELS_VALIDATION_MSG;
        if(roles.length < 1) return SKILLS_VALIDATION_MSG;
        if(topIndustries.length < 1) return INDUSTRIES_VALIDATION_MSG;
    }

    const uploadPayloadBuild = () => {
        return {
          documentTypeId: CERTIFICATE_ID,
          documentPath: `certificates/${userDataState.userId}`,
          documentName: userDataState.userId,
          files: acceptedFilesCertificates
        }
      }

    const {
        acceptedFiles: acceptedFilesCertificates,
        open: openCertificates,
      } = useDropzone({
        disabled: false,
        onDrop: () => {
            setCertificationsFile(false);
            setCertificateID('');
        }
    });

    const handleJobType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setJobType((event.target as HTMLInputElement).value);
    };    

    return (
        <React.Fragment>
                <div className="form-text">
                    <Grid container spacing={FORM_GRID_SPACING} sx={{ maxWidth: FORM_MAX_WIDTH, mx: "auto" }}>
                        {
                            isCompany ?
                              <>
                                <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
                                    <Item className="zero-bottom-margin">
                                        <Typography className={classes.Heading}>
                                            {RECRUITERS_NUM_MSG}<span className="asterisk-span"> *</span>
                                        </Typography>
                                    </Item>
                                </Grid>
                                {
                                    buttonOptions.map((data, index) => (
                                        <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={HALF_SIZE_GRID} lg={HALF_SIZE_GRID} 
                                        key={index}
                                        className={classes.limitWidth + " highlight-button-grid"}
                                        style={{
                                                marginBottom: (index === 5 || index === 4) ? 16 : 0
                                            }}>
                                            <ButtonItem>
                                                <Button variant="outlined" fullWidth
                                                    onClick={() => setRecruiterCount(index)}
                                                    className="highlight-button"
                                                    style={{
                                                            backgroundColor: (
                                                                (recruiterCount === index && data === buttonOptions[index])
                                                            ) ? PRIMARY_COLOR: OFF_WHITE_COLOR
                                                    }}
                                                >
                                                        {data}
                                                </Button>
                                            </ButtonItem>
                                        </Grid>
                                    ))
                                }
                              </>
                            : null
                        }
                        <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
                            <Item>
                                <Typography className={classes.Heading} sx={{paddingBottom: '16px'}}>
                                    {`Which job locations ${isCompany ? 'does your company recruit mostly?' : 'do you mostly hire for?'}`}
                                </Typography>
                                <FormControl sx={{ width: FULL_WIDTH_PERCENT, padding: 0, margin: 0 }}>
                                <Autocomplete
                                        options={locations}
                                        multiple
                                        value={selectLocations}
                                        renderTags={() => null}
                                        onChange={handleLocations}
                                        disableCloseOnSelect
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            fullWidth
                                            label={
                                                !selectLocations ? "Please Select" :
                                                selectLocations.join(', ')
                                            }
                                            className={classes.inputField}
                                        />
                                        )}
                                        renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            className="list-checked-icon"
                                            checked={selected}
                                            />
                                            {option}
                                        </li>
                                        )}
                                    />
                                </FormControl>
                                {
                                    selectLocations.length > 0 ? 
                                    <>
                                        {selectLocations?.map((item) => (
                                        <Chip label={item} 
                                            sx={{
                                                [`& .${chipClasses.deleteIcon}`]: {
                                                    color: PRIMARY_THEME_COLOR
                                                }
                                            }}
                                            key={item} 
                                            variant="outlined"
                                            onDelete={() => {
                                                const newArray = handleRemoveChip(item, selectLocations);
                                                if(newArray) setSelectLocations(loc => [...newArray])
                                            }}
                                            className="list-chip-style" 
                                            deleteIcon={<CloseIcon />}
                                        />
                                        ))}
                                    </> : null
                                }
                            </Item>
                        </Grid>
                        <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
                            <Item className="zero-bottom-margin">
                            <Typography className={classes.Heading}>
                            {`How many placements ${isCompany ? 'has your company' : 'have you'} made in the last 12 months?`}
                            <span className="asterisk-span"> *</span>
                            </Typography>
                            </Item>
                        </Grid>
                        {
                            buttonOptions.map((data, index) => (
                                <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={HALF_SIZE_GRID} lg={HALF_SIZE_GRID} 
                                 key={index}
                                 className={classes.limitWidth + " highlight-button-grid"}
                                 style={{
                                        marginBottom: (index === 5 || index === 4) ? 16 : 0
                                    }}>
                                    <ButtonItem>
                                        <Button variant="outlined" fullWidth
                                            onClick={() => setPlacementCount(index)}
                                            className="highlight-button"
                                            style={{
                                                    backgroundColor: (
                                                        (placementCount === index && data === buttonOptions[index])
                                                    ) ? PRIMARY_COLOR: OFF_WHITE_COLOR
                                            }}
                                        >
                                                {data}
                                        </Button>
                                    </ButtonItem>
                                </Grid>
                            ))
                        }
                        <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
                            <Item>
                                <Typography className={classes.Heading}>
                                {`Please select up to 5 functional roles that represent your ${isCompany ? 'company' : ''} recruiting expertise`}<span className="asterisk-span"> *</span>
                                </Typography>
                                <FormControl sx={{ width: FULL_WIDTH_PERCENT, padding: 0, margin: 0, paddingTop: '20px' }}>
                                    <Autocomplete
                                        options={functionalRoles}
                                        multiple
                                        value={roles}
                                        renderTags={() => null}
                                        onChange={handleFunctionalroles}
                                        disableCloseOnSelect
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label={
                                                !roles ? "Please Select *" :
                                                roles.join(', ')
                                            }
                                            className={classes.inputField}
                                        />
                                        )}
                                        renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            className="list-checked-icon"
                                            checked={selected}
                                            />
                                            {option}
                                        </li>
                                        )}
                                    />
                                </FormControl>
                                {
                                    roles.length > 0 ? 
                                    <>
                                        {roles?.map((item) => (
                                        <Chip 
                                            sx={{
                                              [`& .${chipClasses.deleteIcon}`]: {
                                                color: PRIMARY_THEME_COLOR
                                              }
                                            }}
                                            label={item} 
                                            key={item} 
                                            onDelete={() => {
                                                const newArray = handleRemoveChip(item, roles);
                                                if(newArray) setRoles(role => [...newArray])
                                            }}
                                            className="list-chip-style" 
                                            deleteIcon={<CloseIcon />}
                                        />
                                        ))}
                                    </> : null
                                }
                            </Item>
                        </Grid>
                        <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
                            <Item>
                                <Typography className={classes.Heading}>
                                {`Types of requirements ${isCompany ? 'your company' : 'you have'} handled?`}<span className="asterisk-span"> *</span>
                                </Typography>
                                <FormControl sx={{ width: FULL_WIDTH_PERCENT, padding: 0, margin: 0 }}>
                                    <Autocomplete
                                        options={requirements}
                                        multiple
                                        value={selectRequirements}
                                        renderTags={() => null}
                                        onChange={handleRequirements}
                                        disableCloseOnSelect
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label={
                                                !selectRequirements ? "Please Select *" :
                                                selectRequirements.join(', ')
                                            }
                                            className={classes.inputField}
                                        />
                                        )}
                                        renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            className="list-checked-icon"
                                            checked={selected}
                                            />
                                            {option}
                                        </li>
                                        )}
                                    />
                                </FormControl>
                                {
                                    selectRequirements.length > 0 ? 
                                    <>
                                        {selectRequirements?.map((item) => (
                                        <Chip 
                                            sx={{
                                              [`& .${chipClasses.deleteIcon}`]: {
                                                color: PRIMARY_THEME_COLOR
                                              }
                                            }}
                                            label={item} 
                                            key={item} 
                                            onDelete={() => {
                                                const newArray = handleRemoveChip(item, selectRequirements);
                                                if(newArray) setSelectRequirements(requirement => [...newArray]);
                                                if(item === ALL_FILTER) setSelectRequirements(requirement => []);
                                            }}
                                            className="list-chip-style" 
                                            deleteIcon={<CloseIcon />}
                                        />
                                        ))}
                                    </> : null
                                }
                            </Item>
                        </Grid>
                        <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
                            <Item>
                                <Typography className={classes.Heading}>
                                {`At what levels ${isCompany ? 'does your company recruit mostly?' : 'do you hire talent?'}`}
                                <span className="asterisk-span"> *</span>
                                </Typography>
                                <FormControl sx={{ width: FULL_WIDTH_PERCENT, padding: 0, margin: 0 }}>
                                    <Autocomplete
                                        options={levels}
                                        multiple
                                        value={selectLevels}
                                        renderTags={() => null}
                                        onChange={handleLevels}
                                        disableCloseOnSelect
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            fullWidth
                                            required
                                            label={
                                                !selectLevels ? "Please Select *" :
                                                selectLevels.join(', ')
                                            }
                                            className={classes.inputField}
                                        />
                                        )}
                                        renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            className="list-checked-icon"
                                            checked={selected}
                                            />
                                            {option}
                                        </li>
                                        )}
                                    />
                                </FormControl>
                                {
                                    selectLevels.length > 0 ? 
                                    <>
                                        {selectLevels?.map((item) => (
                                        <Chip 
                                            sx={{
                                              [`& .${chipClasses.deleteIcon}`]: {
                                                color: PRIMARY_THEME_COLOR
                                              }
                                            }}
                                            label={item} 
                                            key={item} 
                                            onDelete={() => {
                                                const newArray = handleRemoveChip(item, selectLevels);
                                                if(newArray) setSelectLevels(level => [...newArray]);
                                                if(item === ALL_FILTER) setSelectLevels(level => []);
                                            }}
                                            className="list-chip-style" 
                                            deleteIcon={<CloseIcon />}
                                        />
                                        ))}
                                    </> : null
                                }
                            </Item>
                        </Grid>
                        {
                            !isCompany ?
                                <>
                                    <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
                                        <Item className="typography-item">
                                            <Typography className={classes.Heading}>
                                                {RECRUITMENT_CERTIFICATIONS_MSG}
                                            </Typography>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={HALF_SIZE_GRID} lg={HALF_SIZE_GRID} className={classes.limitWidth + ' highlight-grid-pt'}>
                                        <ButtonItem>
                                                <Button variant="outlined" fullWidth
                                                onClick={() => setCertification(true)}
                                                className="highlight-button"
                                                style={{backgroundColor: certification ? PRIMARY_COLOR: OFF_WHITE_COLOR}}>
                                                    Yes
                                                </Button>
                                        </ButtonItem>
                                    </Grid>
                                    <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={HALF_SIZE_GRID} lg={HALF_SIZE_GRID} className={classes.limitWidth + ' highlight-grid-pt'}>
                                        <ButtonItem>
                                                <Button variant="outlined" fullWidth
                                                onClick={() => setCertification(false)}
                                                className="highlight-button"
                                                style={{backgroundColor: !certification ? PRIMARY_COLOR: OFF_WHITE_COLOR}}>
                                                    No
                                                </Button>
                                        </ButtonItem>
                                    </Grid>
                                    {
                                        certification ? 
                                            <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} 
                                            className={classes.limitWidth} sx={{paddingTop: 0}}>
                                                <Box
                                                    sx={{
                                                    display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                                                    justifyContent: "space-between",
                                                    px: 2,
                                                    mb: 3
                                                    }}
                                                    className={classes.limitWidth}
                                                >
                                                    {
                                                        acceptedFilesCertificates.length > 0 ?
                                                        <Box>
                                                            <aside>
                                                                {
                                                                    certificationsFile ? 
                                                                    recruiterFiles.map((file: any) => (
                                                                        <li key={file.path || file.name}>
                                                                        {userDataState.userId} - {file.size} bytes
                                                                        </li>
                                                                    )) : 
                                                                    acceptedFilesCertificates.map((file: any) => (
                                                                        <li key={file.path || file.name}>
                                                                        {file.path} - {file.size} bytes
                                                                        </li>
                                                                    ))
                                                                }
                                                            </aside>
                                                            <Typography>Uploaded Successfully</Typography>
                                                        </Box> : 
                                                        <Box>
                                                            <Box
                                                                textAlign="left"
                                                                onClick={openCertificates}
                                                                className={classes.browseFiles}
                                                            >
                                                                {BROWSE_FILE_MSG}
                                                            </Box>
                                                            <Box>
                                                                {FILE_SIZE_MSG}
                                                            </Box>
                                                        </Box>
                                                    }
                                                    <Box
                                                        textAlign="right"
                                                        onClick={openCertificates}
                                                        className={classes.uploadLogoText}
                                                    >
                                                        Re-Upload
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        : null
                                    }
                                </>
                            : null
                        }
                        {
                            isCompany ?
                                <>
                                    <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
                                        <Item>
                                            <Typography className={classes.Heading}>
                                                {COMPANY_REQUISITION_MSG}<span className="asterisk-span"> *</span>
                                            </Typography>
                                            <FormControl sx={{ width: FULL_WIDTH_PERCENT, padding: 0, margin: 0 }}>
                                                <RadioGroup
                                                aria-labelledby="demo-radio-buttons-group-label"
                                                name="radio-buttons-group"
                                                className="radio-group-margin"
                                                value={jobType}
                                                onChange={handleJobType}
                                                >
                                                    <FormControlLabel
                                                        value="Full Time"
                                                        control={<Radio />}
                                                        label="Full Time"
                                                        className={classes.Radio}
                                                    />
                                                    <FormControlLabel
                                                        value="Contract"
                                                        control={<Radio />}
                                                        label="Contract"
                                                        className={classes.Radio}
                                                    />
                                                    <FormControlLabel
                                                        value="RPO"
                                                        control={<Radio />}
                                                        label="RPO"
                                                        className={classes.Radio}
                                                    />
                                                    <FormControlLabel
                                                        value="other"
                                                        control={<Radio />}
                                                        label="Other"
                                                        className={classes.Radio}
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Item>
                                    </Grid>
                                </>
                            : null
                        }
                        <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
                            <Item className="typography-item">
                            <Typography className={classes.Heading}>
                                {RECRUITMENT_DRIVES_MSG}<span className="asterisk-span"> *</span>
                            </Typography>
                            </Item>
                        </Grid>
                        <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={HALF_SIZE_GRID} lg={HALF_SIZE_GRID} className={classes.limitWidth + ' highlight-grid-pt'}>
                            <ButtonItem>
                                    <Button variant="outlined" fullWidth
                                    onClick={() => setDrives(true)}
                                    className="highlight-button"
                                    style={{backgroundColor: drives ? PRIMARY_COLOR: OFF_WHITE_COLOR}}>
                                        Yes
                                    </Button>
                            </ButtonItem>
                        </Grid>
                        <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={HALF_SIZE_GRID} lg={HALF_SIZE_GRID} className={classes.limitWidth + ' highlight-grid-pt'}>
                            <ButtonItem>
                                    <Button variant="outlined" fullWidth
                                    onClick={() => setDrives(false)}
                                    className="highlight-button"
                                    style={{backgroundColor: !drives ? PRIMARY_COLOR: OFF_WHITE_COLOR}}>
                                        No
                                    </Button>
                            </ButtonItem>
                        </Grid>
                        <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
                            <Item>
                                <Typography className={classes.Heading} sx={{paddingBottom: '16px'}}>
                                {`How long ${isCompany ? 'your company has' : 'have you'} been in domestic recruiting?`}
                                </Typography>
                                <PrettoSlider
                                    aria-label="domestictenure"
                                    valueLabelDisplay="auto"
                                    step={1}
                                    value={domesticTenure}
                                    marks={marks}
                                    min={1}
                                    max={10}
                                    onChange={(e, v) => setDomesticTenure(v || 0)}
                                />
                            </Item>
                        </Grid>
                        {
                            !isCompany ?
                                <>
                                    <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
                                        <Item>
                                            <Typography className={classes.Heading}>
                                                {FREELANCE_TENURE_MSG}
                                            </Typography>
                                            <PrettoSlider
                                                aria-label="freelancetenure"
                                                valueLabelDisplay="auto"
                                                step={1}
                                                value={freelanceTenure}
                                                marks={marks}
                                                min={1}
                                                max={10}
                                                onChange={(e, v) => setFreelanceTenure(v || 0)}
                                            />
                                        </Item>
                                    </Grid>
                                </>
                            : null
                        }
                        <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
                            <Item>
                                <Typography className={classes.Heading}>
                                    {SKILLSET_MSG}
                                </Typography>
                                <CreatableSelect
                                    isMulti
                                    styles={customStyles}
                                    onChange={handleTopSkills}
                                    controlShouldRenderValue={false}
                                    placeholder={topFiveSkills.map(skill => skill.label).join(', ')}
                                    options={topSkillsArray}
                                    value={topFiveSkills}
                                />
                                {
                                    topFiveSkills.length > 0 ? 
                                    <>
                                        {topFiveSkills?.map((item) => (
                                        <Chip 
                                            sx={{
                                              [`& .${chipClasses.deleteIcon}`]: {
                                                color: PRIMARY_THEME_COLOR
                                              }
                                            }}
                                            label={item.value} 
                                            key={item.value} 
                                            className="list-chip-style" 
                                            deleteIcon={<CloseIcon />}
                                            onDelete={() => {
                                                const itemArray = topFiveSkills;
                                                const index = itemArray.findIndex(elem => elem === item);
                                                if(index !== -1) {
                                                    if(itemArray.length === 1) {
                                                        setTopFiveSkills(skill => []);
                                                    } else {
                                                        itemArray.splice(index, 1);
                                                        if(itemArray.length > 0) setTopFiveSkills(skill => [...itemArray])
                                                    }
                                                }
                                            }}
                                        />
                                        ))}
                                    </> : null
                                }
                            </Item>
                        </Grid>
                        <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} className={classes.limitWidth}>
                            <Item>
                                <Typography className={classes.Heading}>
                                    {INDUSTRIES_MSG}<span className="asterisk-span"> *</span>
                                </Typography>
                                <CreatableSelect
                                    isMulti
                                    styles={customStyles}
                                    onChange={handleTopIndustries}
                                    controlShouldRenderValue={false}
                                    placeholder={topIndustries.map(skill => skill.label).join(', ')}
                                    options={industries}
                                    value={topIndustries}
                                />
                                {
                                    topIndustries.length > 0 ? 
                                    <>
                                        {topIndustries?.map((item) => (
                                        <Chip 
                                            sx={{
                                              [`& .${chipClasses.deleteIcon}`]: {
                                                color: PRIMARY_THEME_COLOR
                                              }
                                            }}
                                            label={item.value} 
                                            key={item.value} 
                                            className="list-chip-style" 
                                            deleteIcon={<CloseIcon />}
                                            onDelete={() => {
                                                const itemArray = topIndustries;
                                                const index = itemArray.findIndex(elem => elem === item);
                                                if(index !== -1) {
                                                    if(itemArray.length === 1) {
                                                        setTopIndustries(industry => []);
                                                    } else {
                                                        itemArray.splice(index, 1);
                                                        if(itemArray.length > 0) setTopIndustries(industry => [...itemArray]);
                                                    }
                                                }
                                            }}
                                        />
                                        ))}
                                    </> : null
                                }
                            </Item>
                        </Grid>
                        {
                            loader ? 
                                <div className="center-loader"> 
                                    <CircularProgress /> 
                                </div> : 
                                <>
                                    <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={HALF_SIZE_GRID} lg={HALF_SIZE_GRID} className={classes.limitWidth + ' btn-grid-margin'}>
                                        <Item>
                                            <Button variant="outlined" className="outlined-radius-button"
                                                onClick={(e) => props.handleBack()} 
                                                fullWidth>
                                                {BACK_BTN_TEXT}
                                            </Button>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={HALF_SIZE_GRID} lg={HALF_SIZE_GRID} className={classes.limitWidth + ' btn-grid-margin'}>
                                        <Item>
                                            <Button variant="contained" onClick={submitData}
                                            className="radius-button next-button" fullWidth>
                                                {PROCEED_BTN_TEXT}
                                            </Button>
                                        </Item>
                                    </Grid>
                                </>
                        }
                    </Grid>
                </div>
        </React.Fragment>
    )
}

export default BackgroundDetails;