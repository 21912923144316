import axios from 'axios';
import KeycloakService from "./KeycloakService";

export interface GenerateOTP {
    channel: string,
    to: string,
    from: string,
    subject: string,
    body: string,
}

export interface VerifyOTP {
    channel: string,
    to: string,
    otp: string,
}

export const validateOTP = async (bodyPayload: VerifyOTP) => {
    const token = await KeycloakService.fetchTokenDifferently();
    return axios.post(
        `${process.env.REACT_APP_MAIN_SERVER_URL}accounts/v1/otp/validate`,
         bodyPayload,
        {headers: {Authorization: `Bearer ${token}`}}
    );
};

export const generateOTP = async (bodyPayload: GenerateOTP) => {
    const token = await KeycloakService.fetchTokenDifferently();
    return axios.post(
        `${process.env.REACT_APP_MAIN_SERVER_URL}accounts/v1/otp/generate`,
         bodyPayload,
        {headers: {Authorization: `Bearer ${token}`}}
    );
}