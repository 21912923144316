import {
  useLocation,
  Navigate,
} from "react-router-dom";
import KeycloakService from "../services/KeycloakService";
// import { useAppSelector } from './../services/StoreHooks';

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    let location = useLocation();
    let routeExists = -1;
    const isLoggedIn = KeycloakService.isLoggedIn();
    // const menuDataState = Object.values(useAppSelector((state) => state.currentMenu));
    // if(menuDataState.length > 0) {
    //     routeExists = menuDataState.findIndex(menu => menu?.url === location.pathname.slice(1));
    // }
    if (!isLoggedIn) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
        return children;
    }
  }