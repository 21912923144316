import React, { ReactElement, FC, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import "../App.css";
import { ColorlibStepIcon, ColorlibConnector } from "./StepIcons";
import CompanyFormOne from "./CompanyFormOne";
import BillingDetailsForm from "./BillingDetails/BillingDetailsForm";
import { useAppSelector } from "../services/StoreHooks";
import { getFormData } from "../services/FormDataService";
import { 
  COMPANY_DETAILS_FORM, 
  SKIP_BTN_TEXT
} from "../constants";
import CompanyFormFour from "./CompanyFormFour";
import BackgroundDetails from "./BackgroundDetails/BackgroundDetails";
import {
  FULL_WIDTH_PERCENT
} from '../InternalStyles/CommonStyleVariables';

const OnboardCompanyUser: FC<any> = (props): ReactElement => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [formOneFilled, setFormOneFilled] = React.useState(false);
  const [formOneDetails, setFormOneDetails] = React.useState<any>({});
  const [gotData, setGotData] = React.useState(false);

  const userDataState = useAppSelector((state) => state.currentUser);

  const steps = ["Company", "Background", "Billing", "Add Team"];

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const handleNext = () => {
    if(isLastStep()) {
      props.setComplete(true);
    }
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = (position?: number) => {
    const newCompleted = completed;
    newCompleted[position || activeStep] = true;
    setCompleted(newCompleted);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const getDataFill = async () => {
      const formOneData = await getFormData(COMPANY_DETAILS_FORM, userDataState.userId);
      if(formOneData?.data?.data.length > 0) {
        setFormOneDetails({
          ...formOneData?.data?.data[0].formData,
          id: formOneData?.data?.data[0].id
        });
        handleComplete(0);
        if(props.backgroundDetails.id) handleComplete(1);
        if(props.billingDetails.id) handleComplete(2);
        setFormOneFilled(true);
      }
      setGotData(true);
    };
    getDataFill();
  }, [gotData]);

  return (
    <div>
      <div className="form-body-content">
        <Stack sx={{ width: FULL_WIDTH_PERCENT }} spacing={4}>
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index] === true}>
                <StepLabel 
                  StepIconComponent={ColorlibStepIcon} 
                  className="step-clickable"
                  onClick={() => setActiveStep(index)}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
        {activeStep || formOneFilled ?
          <Button
            onClick={handleNext}
            sx={{ position: "relative", right: "-50%", bottom: "5.18em" }}
          >
            {SKIP_BTN_TEXT}
          </Button>
         : null}
      </div>
      {activeStep + 1 === 1 ? (
        <>
        {
          gotData ? 
          <CompanyFormOne 
            handleNext={handleNext} 
            handleComplete={handleComplete}
            setActiveStep={setActiveStep}
            formOneDetails={formOneDetails}
            setOpen={props.setOpen}
            setType={props.setType}
            setDataMessage={props.setDataMessage}
            setFormOneFilled={setFormOneFilled}
            referenceData={props.referenceData}
          /> : null
        }
        </>
      ) : activeStep + 1 === 2 ? (
        <BackgroundDetails 
          handleNext={handleNext}
          handleBack={handleBack}
          handleComplete={handleComplete}
          referenceData={props.referenceData}
          setOpen={props.setOpen}
          setType={props.setType}
          setDataMessage={props.setDataMessage}
          backgroundDetails={props.backgroundDetails}
        />
      ) : activeStep + 1 === 3 ? (
        <BillingDetailsForm 
          handleNext={handleNext} 
          handleBack={handleBack}
          handleComplete={handleComplete}
          setOpen={props.setOpen}
          setType={props.setType}
          setDataMessage={props.setDataMessage} 
          billingDetails={props.billingDetails}
        />
      ) : (
          <CompanyFormFour 
            handleNext={handleNext} 
            handleBack={handleBack}
            handleComplete={handleComplete}
          />
      )}
    </div>
  );
};

export default OnboardCompanyUser;
