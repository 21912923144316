import { FC } from "react";
import React, { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { useFormik, getIn } from "formik";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { CreateUser } from "../services/UserService";
import Notification from "../components/Notification";
import { 
  INTERNAL_GROUP, 
  RECRUITMENT_COMPANY,
  SUCCESS_KEY,
  WARNING_KEY,
  BACK_BTN_TEXT,
  ERROR_KEY,
  LETTERS_ONLY_REGEX,
  NUMBER_ONLY_REGEX
} from "../constants";
import { useAppSelector } from "../services/StoreHooks";
import { ThemeProvider } from '@mui/material/styles';
import { 
  useStyles,
  formLabelsTheme,
  StyledContainer 
} from "../InternalStyles/CompanyFormFourStyles";
import {
  FIRSTNAME_MSG,
  LASTNAME_MSG,
  EMAIL_ERR_MSG,
  EMAIL_REQ_MSG,
  PHN_ERR_MSG,
  PHN_REQ_MSG,
  TEAM_REQ_MSG, 
  TEAM_MIN_MSG,
  SUBMIT_BTN_MSG,
  USER_ADD_ERR_MSG,
  COUNTRY_CODE_MSG,
  ADD_RECRUITER_MSG,
  SPECIAL_CHAR_ERR_MSG,
  RECRUITER_DETAIL_MSG,
  RECRUITER_ADD_TITLE,
} from "../Messages/CompanyFormFourMessages";
import { FormAttributes } from "../ConstantValues/CompanyFormFourConstants";
import {
  AUTO_MARGIN, FORM_GRID_SPACING,
  FULL_SIZE_GRID, HALF_SIZE_GRID,
  PRIMARY_THEME_COLOR, 
} from '../InternalStyles/CommonStyleVariables';

const CompanyFormFour: FC<any> = (props) => {
  const classes = useStyles();

  const [showNotifcation, setShowNofitication] = useState(false);
  const [notifcationMessage, setNoticationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const userDataState = useAppSelector((state) => state.currentUser);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const initialValuesForForm = {
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
  };
  const teamArray: any[] = [];
  const formFour = useFormik({
    initialValues: { members: teamArray },
    validationSchema: Yup.object().shape({
      members: Yup.array()
        .of(
          Yup.object({
            firstName: Yup.string().required(FIRSTNAME_MSG).min(3).max(FormAttributes.firstName.maxLength).matches(LETTERS_ONLY_REGEX, SPECIAL_CHAR_ERR_MSG),
            lastName: Yup.string().required(LASTNAME_MSG).min(1).max(FormAttributes.lastName.maxLength).matches(LETTERS_ONLY_REGEX, SPECIAL_CHAR_ERR_MSG),
            email: Yup.string()
              .email(EMAIL_ERR_MSG).min(6).max(FormAttributes.emailId.maxLength)
              .required(EMAIL_REQ_MSG),
            countryCode: Yup.string().required(COUNTRY_CODE_MSG).min(1).max(FormAttributes.countryCode.maxLength).matches(NUMBER_ONLY_REGEX),
            phoneNumber: Yup.string().max(FormAttributes.phoneNumber.maxLength)
              .matches(phoneRegExp, PHN_ERR_MSG).length(10)
              .required(PHN_REQ_MSG),
          })
        )
        .required(TEAM_REQ_MSG)
        .min(1, TEAM_MIN_MSG),
    }),
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values, setSubmitting);
    },
    enableReinitialize: true,
  });

  const handleSubmit = async (values: any, setSubmitting: any) => {

    const { members } = values;

    const userPayload = members.map((item: any) => ({
          firstName: item.firstName,
          lastName: item.lastName,
          userName: item.countryCode + item.phoneNumber,
          mobileNumber: item.countryCode + item.phoneNumber,
          emailId: item.email,
          department: "dummy",
          userType: RECRUITMENT_COMPANY,
          groups: [INTERNAL_GROUP],
          internalRecruiter: true,
          mobile2: "",
          companyId: userDataState.userData.companyId,
          channel: "Company Onboard",
          otherChannel: "",
    }));
    const userResponse = userPayload.map(
      async (teamMember: any, index: number) => {
        const responseCreate = await CreateUser(teamMember, true);
        // if(responseCreate?.data?.success && responseCreate?.data?.data?.userData) {
        //   const index = userPayload.findIndex((data: {userData: any}) => 
        //   data.userData.firstName === responseCreate?.data?.data?.userData.firstName);
        //   if(index !== -1) handleServiceRemove(index);
        //   setShowNofitication(true);
        //   setNotificationType(SUCCESS_KEY);
        //   setNoticationMessage(`Successfully signed up the user ${responseCreate?.data?.data?.userData.firstName}`);
        // }
      }
    );
    props.handleComplete();
    props.handleNext()
    if(serviceList.length > 0) {
      setShowNofitication(true);
      setNotificationType(ERROR_KEY);
      setNoticationMessage(USER_ADD_ERR_MSG);
    }
    setSubmitting(false);
    // handleNotification(userResponse);

    // postFormValues(values, userId);

    // formFour.setValues({
    //   ...apiData
    // })
  };

  // const handleNotification = (Response: any) => {
  //   console.log(Response);
  //   if (Response?.data?.success) {
  //     setShowNofitication(true);
  //     setNotificationType(SUCCESS_KEY);
  //     setNoticationMessage("Recruiter Details Submitted Successfully");
  //     setTimeout(() => {
  //       setNoticationMessage("");
  //       setNotificationType("");
  //       setShowNofitication(false);
  //     }, 3500);
  //   } else {
  //     setNoticationMessage("Submit Request is Failed");
  //     setNotificationType(ERROR_KEY);
  //     setShowNofitication(true);
  //     setTimeout(() => {
  //       setNoticationMessage("");
  //       setNotificationType("");
  //       setShowNofitication(false);
  //     }, 3000);
  //   }
  // };

  const [serviceList, setServiceList] = useState<any>([]);

  //   const handleServiceChange = (e, index) => {
  //     const { name, value } = e.target;
  //     const list = [...serviceList];
  //     list[index][name] = value;
  //     setServiceList(list);
  //   };

  const handleServiceRemove = (index: any) => {
    const list = [...serviceList];
    list.splice(index, 1);
    setServiceList(list);
  };

  const handleServiceAdd = () => {
    formFour.setValues((prevValues) => ({
      members: [...prevValues.members, { ...initialValuesForForm }],
    }));
    setServiceList((prevState: any) => [...prevState, { service: "" }]);
  };

  const getError = (name: string) => {
    const error = getIn(formFour.errors, name);
    const touch = getIn(formFour.touched, name);
    return touch && error ? error : null;
  };

  return (
    <form onSubmit={formFour.handleSubmit}>
      <ThemeProvider theme={formLabelsTheme}>
      <StyledContainer maxWidth="sm">
        <Notification
          message={notifcationMessage}
          type={notificationType}
          open={showNotifcation}
        />
        <Grid container spacing={FORM_GRID_SPACING} sx={{ maxWidth: "407px", mx: "auto" }}>
          <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID}>
            <Typography variant="h5" className={classes.Heading}>
              {ADD_RECRUITER_MSG}
            </Typography>
          </Grid>
          <Grid item xs={FULL_SIZE_GRID} sm={FULL_SIZE_GRID} md={FULL_SIZE_GRID} lg={FULL_SIZE_GRID} marginBottom={HALF_SIZE_GRID}>
            <Button
              fullWidth
              variant="outlined"
              sx={{
                borderRadius: "12px",
                height: "44px",
                fontFamily: "Nunito",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "22px",
              }}
              onClick={handleServiceAdd}
            >
              <AddIcon className="add-team-icon"/> {RECRUITER_ADD_TITLE}
            </Button>
          </Grid>

          {serviceList.length > 0 &&
            serviceList.map((singleService: any, index: any) => (
              <div key={index} className="services">
                <div className="first-division">
                  <StyledContainer maxWidth="sm">
                    <Grid container sx={{ maxWidth: "407px", mx: "auto" }} className={classes.muiContainer}>
                      <Grid
                        item
                        xs={FULL_SIZE_GRID}
                        sm={FULL_SIZE_GRID}
                        md={FULL_SIZE_GRID}
                        lg={FULL_SIZE_GRID}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: 3,
                        }}
                      >
                        <Typography variant="h5" className={classes.Heading2}>
                          {RECRUITER_DETAIL_MSG} {index + 1}
                        </Typography>

                        {serviceList.length !== 0 && (
                          <Button
                            type="button"
                            onClick={() => handleServiceRemove(index)}
                            className="remove-btn"
                          >
                            <DeleteIcon color={ERROR_KEY} />
                          </Button>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={FULL_SIZE_GRID}
                        sm={FULL_SIZE_GRID}
                        md={FULL_SIZE_GRID}
                        lg={FULL_SIZE_GRID}
                        className="add-team-grid"
                      >
                        <TextField
                          required
                          id={FormAttributes.firstName.id}
                          label={FormAttributes.firstName.label}
                          className={classes.inputField}
                          fullWidth
                          name={`members[${index}].firstName`}
                          onBlur={formFour.handleBlur}
                          onChange={formFour.handleChange}
                          value={formFour.values.members[index].firstName}
                          error={getError(`members[${index}].firstName`)}
                          helperText={getError(`members[${index}].firstName`)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={FULL_SIZE_GRID}
                        sm={FULL_SIZE_GRID}
                        md={FULL_SIZE_GRID}
                        lg={FULL_SIZE_GRID}
                        className="add-team-grid"
                      >
                        <TextField
                          required
                          id={FormAttributes.lastName.id}
                          label={FormAttributes.lastName.label}
                          className={classes.inputField}
                          fullWidth
                          name={`members[${index}].lastName`}
                          onBlur={formFour.handleBlur}
                          onChange={formFour.handleChange}
                          value={formFour.values.members[index].lastName}
                          error={getError(`members[${index}].lastName`)}
                          helperText={getError(`members[${index}].lastName`)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={FULL_SIZE_GRID}
                        sm={FULL_SIZE_GRID}
                        md={FULL_SIZE_GRID}
                        lg={FULL_SIZE_GRID}
                        className="add-team-grid"
                      >
                        <TextField
                          required
                          id={FormAttributes.emailId.id}
                          label={FormAttributes.emailId.label}
                          className={classes.inputField}
                          fullWidth
                          name={`members[${index}].email`}
                          onBlur={formFour.handleBlur}
                          onChange={formFour.handleChange}
                          value={formFour.values.members[index].email}
                          error={getError(`members[${index}].email`)}
                          helperText={getError(`members[${index}].email`)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={FULL_SIZE_GRID}
                        sm={FULL_SIZE_GRID}
                        md={FULL_SIZE_GRID}
                        lg={FULL_SIZE_GRID}
                        className={classes.limitWidth}
                      >
                        <Box className={classes.displayFlex}>
                          <Grid
                            item
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            className={classes.mr2 +  ' add-team-grid'}
                          >
                            <TextField
                              id={FormAttributes.countryCode.id}
                              label={FormAttributes.countryCode.label}
                              required
                              className={classes.inputField}
                              name={`members[${index}].countryCode`}
                              onBlur={formFour.handleBlur}
                              onChange={formFour.handleChange}
                              error={getError(`members[${index}].countryCode`)}
                              helperText={getError(`members[${index}].countryCode`)}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            sm={10}
                            md={10}
                            lg={10}
                            className={classes.limitWidth}
                          >
                            <TextField
                              id={FormAttributes.phoneNumber.id}
                              label={FormAttributes.phoneNumber.label}
                              required
                              fullWidth
                              type="number"
                              className={classes.inputField}
                              name={`members[${index}].phoneNumber`}
                              onBlur={formFour.handleBlur}
                              onChange={formFour.handleChange}
                              value={formFour.values.members[index].phoneNumber}
                              error={getError(`members[${index}].phoneNumber`)}
                              helperText={getError(
                                `members[${index}].phoneNumber`
                              )}
                            />
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </StyledContainer>
                </div>
              </div>
            ))}

          <Grid
            item
            xs={FULL_SIZE_GRID}
            sm={FULL_SIZE_GRID}
            md={HALF_SIZE_GRID}
            lg={HALF_SIZE_GRID}
            className={classes.limitWidth}
          >
            <Button
              fullWidth
              variant="outlined"
              sx={{ height: "42px", borderRadius: "12px" }}
              onClick={(e) => props.handleBack()}
            >
              {BACK_BTN_TEXT}
            </Button>
          </Grid>
          <Grid
            item
            xs={FULL_SIZE_GRID}
            sm={FULL_SIZE_GRID}
            md={HALF_SIZE_GRID}
            lg={HALF_SIZE_GRID}
            className={classes.limitWidth && classes.muiGrid}
          >
            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={serviceList.length < 1}
              // onClick={submitData}
              sx={{
                height: "42px",
                borderRadius: "12px",
                background: PRIMARY_THEME_COLOR,
              }}
            >
              {SUBMIT_BTN_MSG}
            </Button>
          </Grid>
        </Grid>
      </StyledContainer>
      </ThemeProvider>
    </form>
  );
};

export default CompanyFormFour;
