import OnboardBase from "./pages/OnboardBase";
import Signup from "./pages/Signup/Signup";
import { Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import StoreService from "./services/StoreService";
import { ProtectedRoute } from "./components/ProtectedRoute";
import Dashboard from "./pages/Dashboard";

const store = StoreService;

function App() {
  console.log("got into app component for routing");
  return (
    <Provider store={store}>
      <Routes>
        <Route
          path="/signup"
          element={
            <ProtectedRoute>
              <Signup />
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <OnboardBase />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Provider>
  );
}

export default App;
