import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { Container } from "@mui/material";
import {PRIMARY_THEME_COLOR} from './CommonStyleVariables';

export const formLabelsTheme = createTheme({
    components: {
      MuiFormLabel: {
        styleOverrides: {
          asterisk: {
            color: "#db3131",
            "&$error": {
              color: "#db3131",
            },
          },
        },
      },
    },
});
  
export const StyledContainer = styled(Container)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    boxShadow: "none",
}));
  
export const useStyles = makeStyles(() => ({
    inputField: {
      [`& fieldset`]: {
        borderRadius: 12,
      },
    },
    displayFlex: {
      display: "flex",
    },
    mr2: {
      marginRight: 12,
    },
    uploadLogoText: {
      width: "96px",
  
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "22px",
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      color: "#A4A4A4",
    },
    Heading: {
      height: "22px",
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "22px",
      color: "#30374C",
    },
    browseFiles: {
      width: "228px",
      height: "22px",
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "22px",
      color: PRIMARY_THEME_COLOR,
    },
    inputFieldCustom: {
      [`& fieldset`]: {
        borderRadius: 12,
      },
    },
    limitWidth: {
      width: 402,
    },
    limitLeft: {
      width: 402,
      "@media (min-width: 901px)": {
        paddingRight: "10px",
      }
    },
    limitRight: {
      width: 402,
      "@media (min-width: 901px)": {
        paddingLeft: "10px !important",
      }
    }
}));