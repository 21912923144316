import React, { ReactElement, FC, useEffect } from "react";
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import '../App.css';
import {InternalStepIcon, ColorlibConnector} from './StepIcons';
import FreelancerFormOne from './FreelanceForm/FreelanceFormOne';
import BackgroundDetails from './BackgroundDetails/BackgroundDetails';
import { useAppSelector } from "../services/StoreHooks";
import { getFormData } from "../services/FormDataService";
import { 
  FREELANCE_DETAILS_FORM, 
  SKIP_BTN_TEXT
} from "../constants";
import {
  FULL_WIDTH_PERCENT
} from '../InternalStyles/CommonStyleVariables';

const OnboardInternalRecruiter: FC<any> = (props): ReactElement => {

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState<{
      [k: number]: boolean;
    }>({});
    const [formOneFilled, setFormOneFilled] = React.useState(false);
    const [formOneDetails, setFormOneDetails] = React.useState<any>({});
    const [gotData, setGotData] = React.useState(false);

    const userDataState = useAppSelector((state) => state.currentUser);

    useEffect(() => {
      const getDataFill = async () => {
        const fillResponse = await getFormData(
          FREELANCE_DETAILS_FORM,
          userDataState.userId
        );
        if (fillResponse && fillResponse?.data?.data.length > 0) {
          setFormOneDetails({
            ...fillResponse?.data?.data[0].formData,
            id: fillResponse?.data?.data[0].id
          });
          handleComplete(0);
          if(props?.backgroundDetails?.id) handleComplete(1);
          setFormOneFilled(true);
        }
        setGotData(true);
      };
      getDataFill();
    }, [gotData]);
  
    const steps = ['Personal Details', 'Background Details'];
  
    const isLastStep = () => {
      return activeStep === steps.length - 1;
    };
  
    const handleNext = () => {
      if(isLastStep()) {
        props.setComplete(true);
      }
      const newActiveStep = activeStep + 1;
      setActiveStep(newActiveStep);
    };

    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleComplete = (position?: number) => {
      const newCompleted = completed;
      newCompleted[position || activeStep] = true;
      setCompleted(newCompleted);
    };

    return (
        <div>
            <div className="form-body-content">
              <Stack sx={{ width: FULL_WIDTH_PERCENT }} spacing={4}>
                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                    {steps.map((label, index) => (
                    <Step key={label} completed={completed[index] === true}>
                        <StepLabel 
                          StepIconComponent={InternalStepIcon}
                          onClick={() => setActiveStep(index)}
                          className="step-clickable"
                        >
                          {label}
                        </StepLabel>
                    </Step>
                    ))}
                </Stepper>
              </Stack>
              {activeStep || formOneFilled ?
                <Button onClick={handleNext} sx={{ position: 'relative', right: '-50%', bottom: '5.18em' }}>
                  {SKIP_BTN_TEXT}
                </Button>
               : null}
            </div>
            {activeStep + 1 === 1 ? (
                <>
                {
                  gotData ? 
                  <FreelancerFormOne 
                    handleNext={handleNext}
                    handleComplete={handleComplete}
                    formOneDetails={formOneDetails}
                    referenceData={props.referenceData}
                    setOpen={props.setOpen}
                    setType={props.setType}
                    setDataMessage={props.setDataMessage} 
                    setFormOneFilled={setFormOneFilled}
                  /> : null
                }
                </>
            ) : <BackgroundDetails 
                  handleNext={handleNext} 
                  handleBack={handleBack}
                  handleComplete={handleComplete}
                  referenceData={props.referenceData}
                  setOpen={props.setOpen}
                  setType={props.setType}
                  setDataMessage={props.setDataMessage} 
                  backgroundDetails={props.backgroundDetails}
                />
            }
        </div>
    )

}

export default OnboardInternalRecruiter;